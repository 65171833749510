<template>
  <div class="siginup">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="手机号码"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>

      <el-form-item  prop="phoneCode" class="pr">
              <el-input placeholder="短信验证码" name="phoneCode" ref="phoneCode" v-model="loginForm.phoneCode"></el-input>
              <button @click.prevent="getCode()"  class="code-btn" :disabled="!show">
                  <span v-show="show">获取验证码</span>
                  <span v-show="!show" class="count">{{count}} s</span>
              </button>
      </el-form-item>

      <el-tooltip
        v-model="capsTooltip"
        content="Caps lock is On"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
      </el-tooltip>

      <div class="tip">
        <el-form-item prop="checked">
        <el-checkbox v-model="checked"
          >已阅读并同意此系统 [ <a href="/html/terms.html" class="tipafont" target="_blank" >服务协议</a> ]</el-checkbox
        >
        </el-form-item>
        
        <br />
        <br />
      </div>
      

      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin"
        >注册</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { verifytelphone, validEmail } from '@/utils/validate'
import { getCode,getAvsteduTokenLogin } from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  name: 'Signup',
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters(['token', 'roles','edutoken']),
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error('电话号码不能为空！'))
      } else if (value.length < 3) {
        callback(new Error('输入长度必须大于3位！'))
      } else if (value.length == 11) {
        if (!verifytelphone(value)) {
          callback(new Error('输入手机号码错误！'))
        } else {
          callback()
        }
      } 
      // else if (!validEmail(value)) {
      //   callback(new Error(this.$t('na_common.loginmailerrortips')))
      // } 
      else {
        callback(new Error('输入手机号码错误！'))
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('输入密码不能少于6位！'))
      } else {
        callback()
      }
    }

    const validatephonecode = (rule, value, callback) => {
      if (value.length != 6) {
        callback(new Error('输入长度必须为6位！'))
      } else {
        callback()
      }
    }

     const validateChecked = (rule, value, callback) => {
      if (!this.checked) {
        callback(new Error('必须接受此协议中的条款，才能使用本程序'))
      } else {
        callback()
      }
    }


    return {
      loginForm: {
        username: '',
        password: '',
        phoneCode: '',
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername },
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword },
        ],
        phoneCode: [
          { required: true, trigger: 'blur', validator: validatephonecode },
        ],
         checked: [
          { required: true, trigger: 'blur', validator: validateChecked },
        ],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      show: true,
      timer: null,
      count: 60,
      checked: false,
    }
  },
  methods: {
    // 获取短信验证码
    getCode() {
      if (!verifytelphone(this.loginForm.username)) {
          this.$message.error('输入手机号码错误！')
          return
        } 
       //axios请求
      var form = new FormData()
      form.append('phone', this.loginForm.username)
      getCode(form).then((response) => {
        //this.$message.success(this.$t('na_common.loginsuccess'))
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      }).catch((error) => {
        this.$message.error('验证码发送失败')
      })
      
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          //验证一下协议是否选中
          // if(!checked){

          // }

          this.loading = true
          this.$store
            .dispatch('user/signup', this.loginForm)
            .then(() => {
              this.$router.push({
                path: this.redirect || '/',
                query: this.otherQuery,
              })
              this.loading = false

              //edulinetoken登录
              // var avstloginform = new FormData()
              // avstloginform.append('logstr', this.edutoken)
              // getAvsteduTokenLogin(avstloginform).then((response) => {
              //   console.log(response)
              // })
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.siginup .svg-container {
  padding: 6px 5px 6px 15px;
  color: #889aa4;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}
.svg-icon {
  color: white;
}

.login-container .tip {
 
  ::v-deep .el-form-item__content{
      background: transparent;
  }
  ::v-deep .el-form-item{
      background: transparent;
  }
::v-deep .el-form-item .tipafont{
      color: #1890ff;
      text-decoration: none;
  }
  ::v-deep .el-form-item .tipafont:hover{
      color: #1890ff;
  }


}

/* 短信验证码css */
.pr {
position: relative;
}
.code-btn {
    width: 100px;
    height: 47px;
    position: absolute;
    /* top: 10px; */
    right: 0px;
    z-index: 222;
    /* color: #ef8466; */
    font-size: 14px;
    border: 0px;
    /* border-left: 1px solid #bababa; */
    padding-left: 10px;
    background-color: #fff;
    cursor: pointer;

}
</style>
