const state = {
   newsnum: null,
}

const mutations = {
 SET_NEWSNUM: (state, newsnum) => {
   state.newsnum = newsnum
 },
}

const actions = {
 setnewsnum({ commit }, newsnum) {
   commit('SET_NEWSNUM', newsnum)
 },
}

export default {
 namespaced: true,
 state,
 mutations,
 actions
}
