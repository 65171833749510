const state = {
   payqrcode: null,
   oid: null,
   backurl : null,
   price : null,
}

const mutations = {
  SET_PAYQRCODE: (state, wachatitem) => {
    console.log('vuex payqrcode', wachatitem.payqrcode)
    state.payqrcode = wachatitem.payqrcode
    console.log('vuex oid', wachatitem.oid)
    state.oid = wachatitem.oid
    console.log('vuex backurl', wachatitem.backurl)
    state.backurl = wachatitem.backurl
    console.log('vuex price', wachatitem.price)
    state.price = wachatitem.price
  },
  CLEAR_PAYQRCODE: (state) => {
    state.payqrcode = null
    state.oid = null
    state.backurl = null
    state.price = null
  },
}

const actions = {
  setpayqrcode({ commit }, wachatitem) {
    commit('SET_PAYQRCODE', wachatitem)
  },
  clearpayqrcode({ commit }) {
    commit('CLEAR_PAYQRCODE')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
