import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/icao/login/index',
    method: 'post',
    data
  })
}

export function signup(data) {
  return request({
    url: '/icao/login/reg',
    method: 'post',
    data
  })
}


export function UpdatePassword(data) {
  return request({
    url: '/icao/Login/changePwd',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/icao/user/info',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/icao/login/out',
    method: 'post'
  })
}



export function getCode(data) {
  return request({
    url: '/icao/login/getCode',
    method: 'post',
    data
  })
}

export function saveInfo(data) {
  return request({
    url: '/icao/user/saveInfo',
    method: 'post',
    data
  })
}

/**
 * 获取各个分类的级别价目表
 * @param {*} token  用户token
 * @returns  
 */
export function getPaygoodList(token) {
  return request({
    url: '/icao/good/list',
    method: 'get',
    params: { token }
  })
}


/**
 * 获取各个分类的级别价目表
 * @param {*} token  用户token
 * @returns  
 */
export function getFrontVipPriceList(token) {
  return request({
    url: '/icao/good/getlist',
    method: 'get',
    params: { token }
  })
}

/**
 * 获取alipay支付链接
 * @param {*} data 
 * @returns 
 */
export function getAlipayUrl(data) {
  return request({
    url: '/icao/good/alipay',
    method: 'post',
    data
  })
}

/**
 * 获取微信支付二维码
 * @param {*} data 
 * @returns 
 */
export function getWxPayUrl(data) {
  return request({
    url: '/icao/good/wxpay',
    method: 'post',
    data
  })
}


/**
 * 检查微信支付是否成功
 * @param {*} data 
 * @returns 
 */
export function checkWxOrder(data) {
  return request({
    url: '/icao/good/checkWxOrder',
    method: 'post',
    data
  })
}


/**
 * 开通体验会员
 * @param {*} data 
 * @returns 
 */
export function openTrialPay(data) {
  return request({
    url: '/icao/good/trialPay',
    method: 'post',
    data
  })
}


/**
 * 获取订单列表
 * @param {*} token  用户token
 * @returns  
 */
export function getOrderlist(token) {
  return request({
    url: '/icao/good/orderlist',
    method: 'get',
    params: { token }
  })
}



/**
 * 在线教育同步登录,用户名密码
 * @param {*} token  用户token
 * @returns  
 */
export function getAvsteduLogin(data) {
  return request({
    url: 'https://avstedu.com/index.php?app=basic&mod=Passport&act=ajaxLogin',
    method: 'post',
    data
  })
}


/**
 * 在线教育token登录方式
 * @param {*} token  用户token
 * @returns  
 */
export function getAvsteduTokenLogin(data) {
  return request({
    url: 'https://www.avstedu.com/index.php?app=basic&mod=Passport&act=parseLog',
    method: 'post',
    data
  })
}


/**
 * 注销账号
 * @param {*} token  用户token
 * @returns  
 */
export function accountcancel() {
  return request({
    url: '/icao/user/cancel',
    method: 'get',
  })
}
