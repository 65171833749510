var en = {
    'na_common':{
        'standard': 'Standard',
        'india': 'India',
        'dafault': 'Default',
        'unconventional': 'Unconventional',

        'scoringcompleted': 'Scoring completed',
        'scoringinprogress': 'Scoring in progress',
        'fileuploaded': 'File uploaded',
        'fileuploading': 'File uploading',
        'fileupload': 'File Upload',
        'origin':'Origin',
        'destination': 'Destination',
        'callsign': 'Callsign',
        // 翻译
        'translation': 'translation',
        'deleteok': 'Delete OK',
        'confirm': 'Confirm',
        'deleteconfirm': 'Are you sure you want to delete this record?',
        'delete': 'delete',
        //考试时间
        'examtime': 'Exam Time',
        //开始新的考试
        'startnewexam': 'Start New Exam',
        //继续上次考试
        'continuelastexam': 'Continue Last Exam',
        //系统检测您下方考试并未完成，是否继续上次考试？
        'systemdetectsunfinish': 'The system has detected that you have not completed the exam below. Do you want to continue with the last exam?',
        'accountcancel': 'Account Cancel',
        'instructions':'Instructions',
        'emailaddress':'Email',
        'pleaseinputyouremailaddress':'Please input your email address.',
        'pleaseinputyourfeedbackinformation':'Please input your FeedBack Information.',
        'pleaseinputthebrowseranditsversionnumber':'Please input the browser and its version number.',
        'occurrencetime':'Occurrence Time',
        'selectdate':'Select date',
        'selecttime':'Select time',
        'attachmentupload':'Attachment',
        'audioisbeinguploaded':'Audio is being uploaded...',
        'relearn':'ReLearn',
        'clickandupload':'Click and upload',
        'feedbackinformation':'FeedBack Content',
        'feedbacksuccess':'FeedBack Success',
        'contactus': 'Contact Us',
        'ok':'OK',
        'pleaseselectthecompletedateandtime':'Please select the complete date and time.',

        'brower': 'Brower',
        'continue': 'Continue',
        'course': 'Course',
        'allcourses':'ALL COURSES',
        'alltrain':'ALL Train',
        'self-testing':'Self-testing',
        'allsimulation':'ALL SIMULATION',
        'icaoenglish':'ICAO English',
        'examstart':'Start',
        'trainstart':'Start',
        'testingstart':'START',
        'language':'Language',
        'profilepages':'Profile Pages',
        'changepassword':'Change Password',
        'newpassword':'New Password',
        'confirmpassword':'Confirm Password',
        'tryout':'Try Out', 
        'signup':'Sign Up', 
        'signin':'Sign In', 
        'email':'Email', 
        'mobilePhone':'Moblie Phone', 
        'password':'Password', 
        'tips': 'Tips',
        'error': 'Wrong',
        'success': 'Correct',
        'partcorrect':'Partially Correct',
        'allquestnum':'Question Total',
        'submit':'Submit',
        'nextquestion':'Next',
        'upquestion':'Prev',
        'question': 'Question',
        'answerendtipsright':'Correct Answer!',
        'answerendtipsrequired':'Please complete!',
        'answerendtipswrong':'Wrong Answer!',
        'history': 'History',
        'historyresult': 'Result',
        'historytime': 'Time',
        'loginusernamerequired':'Please enter the correct user name',
        'loginusernamegreaterthan3':'Input length must be greater than 3 digits!',
        'loginphoneerrortips': 'Input mobile phone number error!',
        'loginmailerrortips': 'Input E-mail error!',
        'loginpasswordnotless6': 'The password can not be less than 6 digits',
        'phonecodelenght6': 'The input length must be 6 digits!',
        'messagecode': 'Message Code',
        'getmessagecode': 'Get Code',
        'registrationtips': 'Welcome to come, please complete the registration below',
        'registrationagreement': "I have read and accepted the user's agreement",
        'registrationagreementtip': "You must to accept the terms of this agreement to use this program",

        'recording': 'Recording',
        'finishrecording': 'Finish Recording',
        'upload': 'Upload',
        'uploadcancel': 'Cancel',

        'searchkeyword':'Search Keyword',
        'category':'Category',
        'search':'Search',
        'number':'ID',
        'original':'Text',
        'operation':'Operation',
        'download':'Download',
        'all':'ALL',
        'level':'Level',
        'controller':'Controller',
        'pliot':'Pliot',
        'text':'Text',

        'pleaselistencarefully':'Please listen carefully',
        'nowplay':'Now playing...',
        'recordingremain':'Recording remain',
        'secends':'s',
        'finished':'Finished',

        'score':'Score',
        'scores': 'Scores',
        'getscore': 'Score',
        'status' : 'Status',

        'devicetest':'Device Test',
        'permissionsanddevices':'Permissions And Devices',
        'testingrecord':'Test to Record',
        'Listenrecordandenter':'Listen to Record and Enter',
        'clickbuttontestingpermissionsanddevices':'Click Button Testing Permissions And Devices',
        'clickbuttontostarttestingrecord':'Click the Button to Listen the Tested Record',
        'stoprecording':'Stop Recording',
        'playrecord':'Play the Record',
        'testenter':'OK',
        'textagain':'Test Again',

        'examok':'OK',
        'cancel':'Cancel',
        'show':'Show',
        'exercises' :'Exercises',
        'fullscreen':'full screen',
        'list': 'List',
        'card':'Card',
        'phrasebook':'Phrasebook',
        'save':'Save',
        'ascending': 'Ascending',
        'descending': 'Descending',
        'SimulationHistory': 'Simulation History',
        'waitforgrading': 'Wait For Grading ...',
        'incomplete': 'incomplete',
        'unfinished': 'Unfinished',
        'unqualified': 'Fail',
        'papername': 'paper name',
        'time': 'time',
        'latestnotice': 'Latest Notice',
        'describe': 'Description',
        'sendtime': 'Issue Date',
        'playback': 'Playback',
        'equipmenttesting': 'Equipment Testing',
        'feedback' : 'Feedback',
        'menuaddquestion':'Add Question',

        'easy': 'easy',
        'difficulty': 'difficult',
        
        'modifysuccess': 'Modify Success',
        'subscription' : 'Subscription', 
        'vip' : 'VIP',
        'expirydate' : 'Expiry Date',
        'YouhavenotyetbecomeaVIPmemberAfterbecomingaVIPmemberyoucanenjoymoreservices':'You have not yet become a VIP member. After becoming a VIP member, you can enjoy more services',
        'vipendtime':'Your VIP membership has expired.',
        'membershiprecord' : 'Membership Record',
        'openingtime':'Opening Time',
        'expirationtime':'Expiration Time',
        'openingtype':'Opening Type',
        'additionaldays':'Additional Days',
        'enterprisecustomerservice':'Enterprise Customer Service(WeChat)',
        'close': 'Close',
        'welcomesystem' : 'Welcome to the Civil Aviation English Interactive Learning and Testing System',
        'welcomesystemtrialdesstart' : 'We offer you a ',
        'welcomesystemtrialdesmiddle' : '-day free course experience. So that you can have a deep understanding of our system. Click the button below to open a ',
        'welcomesystemtrialdesend' : '-day free experience.',
        'freeopening' : 'Free activation',
        'welcomesystemexpirationdesstart' : 'Your ',
        'welcomesystemexpirationdesend' : '-day free experience has expired! I am currently unable to study related courses. If you enjoy our course and want to continue learning. You can click on the button below to purchase our course.',
        'purchasecourse' : 'Purchase Course',
        'successfullyopened' : 'Successfully Opened',
        'welcomesystemvipenddes' : 'Your VIP membership has expired! I am currently unable to study related courses. If you still enjoy our course and want to continue learning. You can click on the button below to continue purchasing our course.',
        'scheduleqa' : 'Appointment Q&A',
        'appointmentform' : 'Appointment Form',
        'appointmentlist' : 'Appointment List',
        'appointmentinstructions' : 'Appointment Instructions',    
        'appointmentinstructionsdes' : 'The Q&A session will be conducted via Tencent Meeting. After successful booking, you will receive the Tencent Meeting ID and password via SMS. If you have any questions, please contact customer service.', 
        'appointmenttime' : 'Appointment Time',
        'remainingcount' : 'Remaining Count',
        'notremaingcountdes' : 'No available counts at the moment. Please pay to use.',
        'available': 'Available',
        'times': 'Times',
        'booknow' : 'Book Now',
        'payforbooking': 'Pay for Booking',
        'termsofservice': 'Terms of Service',
        'termsofservicehaveread': 'I have read, understood, and accepted the ',
        'broadcasttime' : 'Broadcast Time',
        'notstarted' : 'Not Started',
        'liveed' : 'Live',
        'ended' : 'Ended',
        'reserved' : 'Reserved',
        'cancelreservation' : 'Cancel Reservation',
        'reservation' : 'Reservation',
        'unpaid' : 'Unpaid',
        'cancelled' : 'Cancelled',
        'more' : 'More',
        'title' : 'Title',
        'appointmentstatus' : 'Appointment Status',
        'coursestatus' : 'Course Status',
        'viewplayback' : 'View Playback',
        'selectpaymentmethod' : 'Select Payment Method',
        'confirmpayment' :  'Confirm Payment',
        'onlyreadpaytipsdes' : 'You must read and accept the agreement to proceed with the payment!',
        'nickname' : 'Nickname',
        'orderlist' : 'Order List',
        'ordernumber' : 'Order Number',
        'paymentmethod' : 'Payment Method',
        'paymenttime' : 'Payment Time',
        'paymentstatus' : 'payment Status',
        'weChatcustomerservice':'WeChat Customer Service',
        'quxiaotips': 'If you need to cancel your appointment, please do so at least 1 hour before the start of the class. After cancellation, the appointment opportunity will be preserved, and you can use it again next time.',
        'selecticaocourses': 'Please select one of the courses below:',
        'hisselecticaocourses': 'Based on your past selections, the system has defaulted to the following course for you:',
    },
    'icao_common': {
        'yingshikecheng' : 'examination-oriented course',
        'icaoencoursespepec':'ICAO English Courses - PEPEC',
        'referenceanswer': 'Reference Answer',
        'interviewwarmuptitle': 'Warm Up',
        'interviewwarmupcontent': "This part is the first stage of the interview, mainly to make candidates familiar with the interviewer's intonation.",
        'interviewwinddowntitle': 'Wind Down',
        'interviewwinddowncontent': 'This part is the fourth stage of the interview, the questions gradually turn to the general topic, so that the examinee mood relaxed, end the exam.',
        'answer_finished_info_ai_score': 'AI is grading, please check in "History" or "Latest Notice"',
        'learningcenter':'Learning Center',
        'viewtheresults':'View The Results',
        'onlyjpgpngmp4file':'Only jpg/png/mp4 files can be uploaded, and the file size cannot exceed 10M.',
        'onlyjpgpngmp4uploaded':'Only jpg/png/mp4 files can be uploaded!',
        'onlyjpgpngmp4tenm':'File size cannot exceed 10M！',
        'feedbackxianzhi1':'Up to',
        'feedbackxianzhi2':'files can be selected,',
        'feedbackxianzhi3':'files have been selected this time,and a total of',
        'feedbackxianzhi4':'files were selected.',

        'story':'Story',
        'selftaught': 'Self-taught',
        'specifictraining': 'Specific Training',
        'simulation': 'Simulation',

        'usercenter': 'User Center',
        'exit':'Exit',

        'knowledgeprinciple':'Knowledge & Principle',
        'phraseologyterminology': 'Phraseology & Terminology',
        'recentcourses':'RECENT COURSES',
        'pepec': 'PEPEC',
        'maintenance':'Maintenance',
        'pilot':'Pilot',
        'flightprinciple':'Flight Principle',
        'flightprinciplecontent':'Principles of Flight , Aerodynamics of Flight , Flight Controls',
        'jeppesenchart':'Flight Operation & Air Navigational Documents',
        'jeppesenchartcontent':'Flight Manuals and Documents , Airport Operations , Airspace',
        'aircraftstructure':'Aircraft Structure',
        'aircraftstructurecontent': 'Aircraft Structure , Aircraft Systems , Flight Instruments',
        'meteorology':'Meteorology & Weather Services',
        'meteorologycontent': 'Weather Theory  , Aviation Weather Services',
        'airnavigationinstruments':'Air Navigation & Instruments',
        'airnavigationinstrumentscontent': 'Flight Instruments , Navigation',
        'flightperformanceBalance':'Flight Performance & Balance',
        'flightperformanceBalancecontent': 'Weight and Balance , Aircraft Performance',
        'CRMhumanfactors':'CRM & Human Factors',
        'CRMhumanfactorscontent': 'Aeromedical Factors , Aeronautical Decision Making',

        'listening':'Listening Comprehension',
        'pepec900':'PEPEC 900',
        'pepec900selftesting':'PEPEC 900 Self-testing',
        'RepeatingRadiotelephonySentences':'Repeating Radiotelephony Sentences',
        'pepec900selftestingstart':'PEPEC 900 Self-testing Start',
        'simulatingradiotelephonycommunication':'Simulating Radiotelephony Communication',
        'simulatingradiotelephonycommunication-selftesting':'Simulating Radiotelephony Communication Self Testing',
        'simulatingradiotelephonycommunication-selftestingscore':'Simulating Radiotelephony Communication Self Testing Score',
        'simulatingradiotelephonycommunication-selftesting-start':'Simulating Radiotelephony Communication Self Testing Start',
        'oralproficiencyinterview':'Oral Proficiency Interview',
        'oralproficiencyinterviewselftesting':'Oral Proficiency Interview Self-testing',
        'oralproficiencyinterviewselftestingstart':'Oral Proficiency Interview Self-testing Start',
        'oralproficiencyinterviewselftestingscore': 'Oral Proficiency Interview Self Testing Score',
        'retellingstory':'Story Retelling',
        'retellingstoryselfstesting':'Story Retelling Self-testing',
        'retellingstoryselfstestingscore':'Story Retelling Self-testing score',
        'retellingstoryselfstestingstart':'Story Retelling Self-testing Start',

        'listenphase': 'Phase',
        'listentype': 'Type',
        'listenerror': 'Error',

        'fillintheblanks':'Fill in the Blanks',
        'fillintheblanksdescribe':'In this part, you are going to hear statements or conversations. Fill in the blanks with the information that you have heard in the recordings. ',
        'listeningcomprehension':'Listening Comprehension',
        'listeningcomprehensiondescribe':' In this part, you are going to hear statements of aviation English or exchanges between pilots and controllers. After you have heard the statements or conversations, you should choose a best answer from four choices. ',
        'listeningandspeaking':'Listening and Speaking',
        'listeningandspeakingdescribe':'In this part, you are going to hear some exchanges or passages. After each exchangs or passages there are three questions,.Listen carefully and then answer the questions.',

        'part1': ' Part I ',
        'part2': ' Part II ',
        'part3': ' Part III ',
        'part4': ' Part IV ',
        'part5': ' Part V ',
        'part6': ' Part VI ',

        'trainendandretrain':'You have completed this training. Do you want to retrain?',
        'continuetrain': 'continue',
        'retrain': 'Retrain',
        'errorqustionnum':'Error',
        'nowrongquestion':'No wrong question',
        'wrongtopic':'Wrong Topic',
        'statementsorconversations':'Statements or Conversations',
        'pleasefillintheblanks' : 'Please fill in the blanks',
        'answeranalysis' : '     Answer Key',

        'correctanswer':'Correct Answer',
        'myanswer':'My Answer',
        'pleasechoosethebestanswer':'Please choose the best answer',

        'exchangesorpassages':'Exchanges or Passages',
        'pleaseanswerthequestions':'Please Answer The Questions',

        'searchconditions':'Search',
        'searchoriginal':'Search',
        'searchlevel':'level',
        'searchscene':'Scene',
        'levelsort':'Sequence',
        'learnprogress':'Progress',
        'answersituation':'Answer',
        'trainevaluation':'Evaluation',
        'playcontrol':'Play Control',
        'singleplay':'Single Play',
        'orderplay':'Order Play',
        'cycleplay':'Random Play',
        'isrecord':'Record',
        'pleaseselectleft':'Please select the sentence on the left!',
        'pleasechecksonicgraph':'Check whether the sonic graph is normal! ',
        'confirmnormal':'OK',
        'replay':'Replay',
        'ready':'Ready',
        'originalhide':'The text is hidden',
        'evaluate':'Evaluate',

        'hundrednextpagetips1':'The audio of this page has been played!',
        'hundrednextpagetips2':'Do you want to go to the next page?',
        'stoppage':'No',
        'innextpage':'Next',
        'hundredcorrectanswer':'Correct Answer',
        'hundredwronganswer':'Wrong Answer',
        'hundredlearned':'Learned',
        'hundrednotlearned':'Not learned',
        'levelascending':'Level Ascending',
        'leveldescending':'Level Descending',

        'repeatpractice':'Repeat Practice',
        'repeatpracticetips':'You will hear departure and arrival ATIS.Repeat the message what you have heard in the ATIS.',
        'roleplayexcercises':'Role-play Excercises',
        'roleplayexcercisestips':'In this part,you will play the role of a pilot and have a conversation with an air traffic controller in a simulated flight.',
        'roleplayexcercisesselftesting': 'Role-play Excercises Self Testing',

        'retelling':'Please start to repeat',
        'interviewready':'Interview preparing...',
        'interviewer':'Interlocator',
        'interviewee':'Interviewee',
        'startinterview':'Start Interview',
        'closeinterview':'Close Interview',

        'exchange':'Exchange',

        'pronunciation':'Pronunciation',
        'structure':'Structure',
        'vocabulary':'Vocabulary',
        'fluency':'Fluency',
        'comprehension':'Comprehension',
        'interaction':'Interaction',

        'icao3tips':'Less Operational Level',
        'icao4tips':'Operational Level',
        'icao5tips':'Advanced Level',
        'pleaseselectpaper':'Select Paper',

        //术语与词汇设置
        'sychlistshowinterpretation':'Interpretation displayed in the list',
        'sychautoaudioplay':'Automatically pronounce words when browsing',
        'sychplayshowinterpretation':'Interpretation directly displayed during playback',
        'sychstudyhabitssetting':'Study Habits Setting',
        'seethedefinition':'See The Definition',
        'moredefinition':'More Definition',
        'detaileddefinition':'Detailed Definition',
        'meaning':'Meaning',
        'coursesentence':'Course Sentence',
        'examplesentences':'Example',

        'word':'Word',
        'phonetic':'Phonetic',
        //规章文献
        'literature':'Document',
    },
    'aets_common': { //空管英语
        'aststitle': 'AETS',
        'airtrafficcontrollers': 'Air Traffic Controllers',
        'listentothenmainidea':'Listen To The Main Idea',
        'storyretelling':'Story Retelling',
        'listenandanswerthequestions':'Listen And Answer The Questions',
        'interactivesimulationtest':'Interactive Simulation Test',
        'oralproficiencyinterview':'Oral Proficiency Interview (OPI)',
        'listeningeasycontent': 'In this part, you will hear normal or abnormal land-to-air conversations, each conversation has a back and forth, after listening to the conversation, you need to complete a multiple-choice question.',
        'listeningdifficultycontent': 'In this part, you will hear normal or abnormal land and air conversations, each conversation has 4-6 back and forth, after listening to the conversation, you need to complete 3-4 multiple choice questions.',
        'listeningshorteasycontent': 'In this part, you will hear a short dialogue. After listening to the dialogue, you need to answer 1 question orally.',
        'listeningshortdifficultycontent': 'In this part, you will hear a long dialogue, after listening to the dialogue, you need to answer 3 questions orally.',
        'roleplayexcercisestips': 'In this part, you will play the role of a controller, taking Wuhan Tianhe Airport as the prototype, and complete the command in English under the simulated international aviation operation scenario.',
        'intervieweasytitle': 'Level Check',
        'intervieweasycontent': 'This part is the second stage of the interview. Questions are gradually introduced to work-related topics, and the language level is initially judged.',
        'interviewdifficultytitle': 'Probe',
        'interviewdifficultycontent': 'This part is the third stage of the interview, which includes picture talking and in-depth conversation to check the language ability.',
    },
    'maintenance_common': { //机务英语
        'maintenance': 'MAINTENANCE',
        'aviationenglish': 'AVIATION ENGLISH',
        'aviationenglishforaircraftmaintenance': 'AVIATION ENGLISH FOR AIRCRAFT MAINTENANCE',
        'CCAR66R3': 'CCAR-66 R3 for Maintenance License',
        'CCAR66R3formaintenancelicense': 'CCAR-66 R3 FOR MAINTENANCE LICENSE',
        'aircraftstructurecontent': 'Aircraft Structure',
        'aircraftsystems': 'Aircraft Systems',
        'aircraftsystemscontent': 'Aircraft Systems , Flight Instruments',
        'listeningpractice': 'Listening practice',
        'readingcomprehension': 'Reading Comprehension',
        'sentences': 'Sentences',
        'exchanges': 'Exchanges',
        'passages': 'Passages',
        'englishtochinese': 'English to Chinese',
        'chinesetoenglish': 'Chinese to English',
        'englishtoenglish': 'English to English',
        'singlechoice': 'Single Choice',
        'navmain': 'Main',
    },
    'cc_common': { //英语Cabin Crew
        'cctitle': 'Cabin Crew',
        'ccflightattendants':'Flight Attendants',
    }
}

export default en