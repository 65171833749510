import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './icons' // icon

import "./utils/drag.js";

// import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client'

//控制token可以访问那些页面
import './permission' // permission control

import i18n from './i18n/index' //国际化

// global filters
import * as filters from './filters' 
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// const options = { path: '/socket.io/' };
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: SocketIO('http://192.168.1.9:8009', options), //options object is Optional
//   vuex: {
//     store,
//     actionPrefix: "SOCKET_",
//     mutationPrefix: "SOCKET_"
//   }
// })
// );

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
