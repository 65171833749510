const state = {
   status: 0,
}

const mutations = {
 SET_ITEMDATAS: (state, status) => {
   state.status = status
 },
}

const actions = {
 setitemData({ commit }, status) {
   commit('SET_ITEMDATAS', status)
 },
}

export default {
 namespaced: true,
 state,
 mutations,
 actions
}
