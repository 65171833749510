import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeCourse from '../views/HomeCourse.vue'
import HomeAbout from '../views/HomeAbout.vue'
import Login from  '../views/login/index.vue'
import LoginChat from  '../views/chatgpt_login_chat/index.vue'
import chatgptTmpChat from  '../views/chatgpt_tmp_chat/index.vue'

//解决报错：Navigation aborted from “/a“ to “/b“ via a navigation guard
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/loginhk',
    name: 'loginhk',
    component: LoginChat
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/hkopi',
    name: 'hkopi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: chatgptTmpChat,
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trainmain/aiinterviewok',
    name: 'ChatgptInterviewok',
    component: () => import(/* webpackChunkName: "about" */ '../views/chatgptfouroandkimiinterview/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/aiinterviewkimi',
    name: 'ChatgptInterviewKimi',
    component: () => import(/* webpackChunkName: "about" */ '../views/chatgptinterviewkimi/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/aiinterview',
    name: 'ChatgptInterview',
    component: () => import(/* webpackChunkName: "about" */ '../views/chatgptinterview/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/aiplusinterview',
    name: 'ChatgptImgInterview',
    component: () => import(/* webpackChunkName: "about" */ '../views/chatgptimginterview/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/demotest',
    name: 'demotest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ademotest/index.vue')
  },
  {
    path: '/demotests',
    name: 'demotests',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ademotests/index.vue')
  },
  {
    path: '/demoteststream',
    name: 'demotestself',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ademotestsself/index.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/index.vue')
  },
  {
    path: '/news/notice',
    name: 'NewsNotice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/newsnotice/index.vue')
  },
  {
    path: '/home/course',
    name: 'HomeCourse',
    component: HomeCourse
  },
  {
    path: '/home/vip',
    name: 'HomeVIP',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeVIP.vue')
  },
  {
    path: '/home/generalquestions',
    name: 'HomeGeneralQuestions',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeGeneralQuestions.vue')
  },
  {
    path: '/home/about',
    name: 'HomeAbout',
    component: HomeAbout
  },
    //反馈
    {
      path: '/Feedback',
      name: 'feedback',
      component: () => import('../views/feedback/index.vue'),
    },
    //wechatpay
    {
      path: '/wechatpay',
      name: 'WechatPay',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/wechatpay/index.vue')
    },
  {
    path: '/user',
    name: 'UsesrCenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/usercenter/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/login/index.vue')
  },
  {
    path: '/coursemain',
    name: 'coursemain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/coursemain/index.vue'),
    meta: [{ title: 'icao_common.selftaught', breadcrumb: true }]   //有翻译的

  },
  {
    path: '/course',
    name: 'course',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/course/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  {
    path: '/coursemain/index',
    name: 'coursepartsindexdel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/coursemaindel/index.vue')
  },
  {
    path: '/coursemain/parts',
    name: 'courseparts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/courseparts/index.vue')
  },

  {
    path: '/coursemain/pparts',
    name: 'coursepartssingle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/coursepartsingle/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  {
    path: '/coursemain/train',
    name: 'coursetrain',
    component: () => import(/* webpackChunkName: "about" */ '../views/coursetrain/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  //短语和术语
  {
    path: '/phraseortermin',
    name: 'phraseortermin',
    component: () => import(/* webpackChunkName: "about" */ '../views/phraseortermin/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/phraseortermin/books',
    name: 'phraseorterminbooks',
    component: () => import(/* webpackChunkName: "about" */ '../views/phraseorterminbooks/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/phraseortermin/card',
    name: 'phraseortermincard',
    component: () => import(/* webpackChunkName: "about" */ '../views/phraseortermincard/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/coursemain", breadcrumb: true },
      { title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  //文献资料
  {
    path: '/literature',
    name: 'literature',
    component: () => import(/* webpackChunkName: "about" */ '../views/literature/index.vue'),
    meta: [{ title: 'icao_common.literature', breadcrumb: true }],
  },
  {
    path: '/trainmain',
    name: 'trainmain',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainmain/index.vue'),
    meta: [{ title: 'icao_common.specifictraining', breadcrumb: true }],
  },
  {
    path: '/trainmain/listen',
    name: 'TrainListen',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlisten/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenb',
    name: 'TrainListenb',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenb/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenerror',
    name: 'TrainListenError',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenError/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', breadcrumb: true },
    ],
  },
  {
    path: '/trainmain/listenerrorblank',
    name: 'TrainListenErrorBlank',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenErrorBlank/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.wrongtopic', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenerrorselect',
    name: 'TrainListenErrorSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenErrorSelect/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.wrongtopic', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenerrorqa',
    name: 'TrainListenErrorQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenErrorQa/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.wrongtopic', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listen/blank',
    name: 'TrainListenBlank',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenblank/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.fillintheblanks', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listen/select',
    name: 'TrainListenSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenselect/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.listeningcomprehension', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listen/qa',
    name: 'TrainListenQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenqa/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/trainmain/listen", breadcrumb: true },
      { title: 'icao_common.listeningandspeaking', breadcrumb: true }
    ],
  },
  // 九百句
  {
    path: '/trainmain/ninehundred',
    name: 'trainninehundred',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundred/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'PEPEC 900', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredhelper',
    name: 'trainninehundredhelper',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredhelper/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'PEPEC 900', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest',
    name: 'TrainListenNinehundredTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.pepec900selftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredteststart',  /* 有历史记录按钮*/
    name: 'TrainListenNinehundredTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.pepec900selftestingstart', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest/history',  /* 有历史记录按钮*/
    name: 'TrainListenNinehundredTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.pepec900selftesting", url: "/trainmain/ninehundredteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest/history/score',  /* 有历史记录按钮*/
    name: 'TrainListenNinehundredTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.pepec900selftesting", url: "/trainmain/ninehundredteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/ninehundredtest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/ninehundredtest/score',
    name: 'TrainListenNinehundredTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainninehundredtestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'PEPEC 900 Self-testing/score', breadcrumb: true }
    ],
  },
  //听力
  {
    path: '/trainmain/listentest',
    name: 'TrainListenTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'Listening Self-testing', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listenteststart',  /* 有历史记录按钮*/
    name: 'TrainListenTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistenteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'Listening Self-testing Start', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listentest/history',  /* 有历史记录按钮*/
    name: 'TrainListenTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "Listening Self-testing", url: "/trainmain/listenteststart", breadcrumb: true },
      { title: 'History', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listentest/history/score',  /* 有历史记录按钮*/
    name: 'TrainListenTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "Listening Self-testing", url: "/trainmain/listenteststart", breadcrumb: true },
      { title: "History", url: "/trainmain/listentest/history", breadcrumb: true },
      { title: 'Score', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/listentest/score',
    name: 'TrainListenTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainlistentestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'Listening Self-testing/score', breadcrumb: true }
    ],
  },

  //情景
  {
    path: '/trainmain/src',
    name: 'TrainSRC',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrc/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/atis',
    name: 'TrainSRCATIS',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcatis/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', url: "/trainmain/src", breadcrumb: true },
      { title: 'ATIS', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/roleplay',
    name: 'TrainSRCRolePlay',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplay/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', url: "/trainmain/src", breadcrumb: true },
      { title: 'icao_common.roleplayexcercises', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/roleplaytest',
    name: 'TrainSRCRolePlayTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftesting-start', url: "/trainmain/roleplayteststart", breadcrumb: true },
      { title: 'icao_common.roleplayexcercisesselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/roleplayteststart',  /* 有历史记录按钮*/
    name: 'TrainSRCRolePlayTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplayteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftesting-start', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/src/roleplaytest/score',
    name: 'TrainSRCRolePlayTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftestingscore', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/roleplaytest/history',  /* 情景历史*/
    name: 'TrainSRCRolePlayTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.simulatingradiotelephonycommunication-selftesting", url: "/trainmain/roleplayteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/roleplaytest/history/score',  /* 情景历史分数*/
    name: 'TrainSRCRolePlayTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainsrcroleplaytesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.simulatingradiotelephonycommunication-selftesting", url: "/trainmain/roleplayteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/roleplaytest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  //训练设备检测
  {
    path: '/trainmain/listen/testdevice',
    name: 'TrainListenTextDevice',
    component: () => import(/* webpackChunkName: "about" */ '../views/listentestdevice/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "na_common.equipmenttesting", breadcrumb: true },
    ],
  },
  //训练面试
  {
    path: '/trainmain/interviewlist',
    name: 'TrainInterviewList',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewlist/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interview',
    name: 'TrainInterview',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterview/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },
    //训练面试
    {
      path: '/trainmain/interviewalioss',
      name: 'TrainInterviewAlioss',
      component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewdemoalioos/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
        { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
      ],
    },
  {
    path: '/trainmain/interviewtest',
    name: 'TrainInterviewTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewteststart',  /* 有历史记录按钮*/
    name: 'TrainInterviewTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftestingstart', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewtest/score',
    name: 'TrainInterviewTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterviewselftestingscore', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewtest/history',
    name: 'TrainInterviewTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.oralproficiencyinterviewselftesting", url: "/trainmain/interviewteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/interviewtest/history/score',
    name: 'TrainInterviewTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/traininterviewtesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.oralproficiencyinterviewselftesting", url: "/trainmain/interviewteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/interviewtest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  {
    path: '/train/history/score',
    name: 'TrainHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainhistoryscore/index.vue'),
    meta: [
      { title: 'na_common.history', url: "/pepec/history", breadcrumb: true },
      // { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  //训练故事复诵 路由
  {
    path: '/trainmain/story',
    name: 'TrainStory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstory', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest',
    name: 'TrainStoryTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstoryselfstesting', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest/score',
    name: 'TrainStoryTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstoryselfstestingscore', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storyteststart',  /* 有历史记录按钮*/
    name: 'TrainStoryTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstoryteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: 'icao_common.retellingstoryselfstestingstart', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest/history',  /* 有历史记录按钮*/
    name: 'TrainStoryTestHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytesthistory/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.retellingstoryselfstesting", url: "/trainmain/storyteststart", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/trainmain/storytest/history/score',  /* 有历史记录按钮*/
    name: 'TrainStoryTestHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/trainstorytesthistoryscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/trainmain", breadcrumb: true },
      { title: "icao_common.retellingstoryselfstesting", url: "/trainmain/storyteststart", breadcrumb: true },
      { title: "na_common.history", url: "/trainmain/storytest/history", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
  //考试部分
  {
    path: '/examlist',
    name: 'ExamList',
    component: () => import(/* webpackChunkName: "about" */ '../views/examlist/index.vue'),
    meta: [
      { title: "icao_common.simulation", breadcrumb: true },
    ],
  },
  {
    path: '/examlist/testdevice',
    name: 'ExamListTextDevice',
    component: () => import(/* webpackChunkName: "about" */ '../views/examtestdevice/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "Equipment Testing", breadcrumb: true },
    ],
  },
  {
    path: '/examlist/instructions',
    name: 'ExamListInstructions',
    component: () => import(/* webpackChunkName: "about" */ '../views/examinstructions/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "INSTRUCATIONS", breadcrumb: true },
    ],
  },
  {
    path: '/exam/listen',
    name: 'ExamListenTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examlistentest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "icao_common.listeningcomprehension", breadcrumb: true }
    ],
  },
  {
    path: '/exam/listen/score',
    name: 'ExamListenTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examlistentestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Listening Comprehension/Score', breadcrumb: true }
    ],
  },
  {
    path: '/exam/repeatingtest',
    name: 'ExamRepeatingTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatingtest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Repeating Radiotelephony Sentences', breadcrumb: true }
    ],
  },
  {
    path: '/exam/repeatinghot',
    name: 'ExamRepeatingHot',
    component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatinghot/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Repeating Radiotelephony Sentences', breadcrumb: true }
    ],
  },
  {
    path: '/exam/repeatingtest/score',
    name: 'ExamRepeatingTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatingtestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Repeating Radiotelephony Sentences/Score', breadcrumb: true }
    ],
  },
  {
    path: '/exam/roleplaytest',
    name: 'ExamRolePlayTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examsrcroleplaytest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'icao_common.roleplayexcercises', breadcrumb: true }
    ],
  },
  {
    path: '/exam/roleplaytest/score',
    name: 'ExamRolePlayTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examsrcroleplaytestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'icao_common.roleplayexcercises', breadcrumb: true }
    ],
  },

  {
    path: '/exam/interview',
    name: 'ExamInterview',
    component: () => import(/* webpackChunkName: "about" */ '../views/examinterview/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },

  {
    path: '/exam/interview/score',
    name: 'ExamInterviewScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examinterviewscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },
  {
    path: '/exam/storytest',
    name: 'ExamStoryTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/examstorytest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Retelling Story', breadcrumb: true }
    ],
  },
  {
    path: '/exam/storytest/score',
    name: 'ExamStoryTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examstorytestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'Retelling Story/Score', breadcrumb: true }
    ],
  },

  {
    path: '/exam/history',  /* 有历史记录按钮*/
    name: 'ExamHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/examhistory/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "na_common.history", breadcrumb: true }
    ],
  },
  {
    path: '/exam/history/score',  /* 有历史记录按钮*/
    name: 'ExamHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examhistoryscore/index.vue'),
    meta: [
      // { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "na_common.history", url: "/pepec/history", breadcrumb: true },
      { title: "na_common.score", breadcrumb: true }
    ],
  },
  {
    path: '/exam/total/score',  /* 有历史记录按钮*/
    name: 'ExamTotalScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/examtotalscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: 'TotalScore', breadcrumb: true }
    ],
  },
  {
    path: '/selfsubmitqa',  /* 有历史记录按钮*/
    name: 'SelfSumbitQA',
    component: () => import(/* webpackChunkName: "about" */ '../views/selfsubmitqa/index.vue'),
    meta: [
      // { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "na_common.menuaddquestion", breadcrumb: true }
    ],
  },

  {
    path: '/onlinebookqa',  /* 在线答疑记录按钮*/
    name: 'OnLineBookQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/onlinebookqa/index.vue'),
    meta: [
      { title: "na_common.scheduleqa",breadcrumb: true },
    ],
  },
  
  {
    path: '/pepec/history',  /* 有历史记录按钮*/
    name: 'PepecHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/historylist/index.vue'),
    meta: [
      { title: "na_common.history", breadcrumb: true }
    ],
  },








  /**
   * 机务路由部分
   */
  {
    path: '/mcourse',
    name: 'jwcourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/jwcourse/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/mcoursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  {
    path: '/mcoursemain',
    name: 'jwcoursemain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/jwcoursemain/index.vue'),
    meta: [{ title: 'icao_common.selftaught', breadcrumb: true }]

  },
  {
    path: '/mcoursemain/train',
    name: 'jwcoursetrain',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwcoursetrain/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/mcoursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  {
    path: '/mtrainmain',
    name: 'jwTrainMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainmain/index.vue'),
    meta: [{ title: 'icao_common.selftaught', breadcrumb: true }],
  },
  {
    path: '/mtrainmain/listen',
    name: 'jwTrainListen',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainlisten/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/mtrainmain", breadcrumb: true },
      { title: 'maintenance_common.navmain', breadcrumb: true }
    ],
  },
  {
    path: '/mtrainmain/listenb',
    name: 'jwTrainListenb',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainlistenb/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/mtrainmain", breadcrumb: true },
      { title: 'maintenance_common.navmain', breadcrumb: true }
    ],
  },
  {
    path: '/mtrainmain/listenerror',
    name: 'jwTrainListenError',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainlistenError/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/mtrainmain", breadcrumb: true },
      { title: 'icao_common.listening', breadcrumb: true },
    ],
  },
  {
    path: '/mtrainmain/listenerrorselect',
    name: 'jwTrainListenErrorSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainlistenErrorSelect/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/mtrainmain", breadcrumb: true },
      { title: 'icao_common.listening', url: "/mtrainmain/listen", breadcrumb: true, routeparams: [{ maintenance: 'maintenance' }, { cate: 'cate' }] },
      { title: 'icao_common.wrongtopic', breadcrumb: true }
    ],
  },
  {
    path: '/mtrainmain/listen/blank',
    name: 'jwTrainListenBlank',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainlistenblank/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/mtrainmain", breadcrumb: true },
      { title: 'Main', url: "/mtrainmain/listen?maintenance=2", breadcrumb: true },
      { title: 'Fill In The Blanks', breadcrumb: true }
    ],
  },
  {
    path: '/mtrainmain/listen/select',
    name: 'jwTrainListenSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainlistenselect/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/mtrainmain", breadcrumb: true },
      { title: 'maintenance_common.navmain', url: "/mtrainmain/listen", breadcrumb: true, routeparams: [{ maintenance: 'maintenance' }, { cate: 'cate' }] },//routeparams 面包屑导航准备
      { title: 'maintenance_common.singlechoice', breadcrumb: true, }
    ],
  },
  {
    path: '/jwtrainmain/listen/qa',
    name: 'jwTrainListenQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwtrainlistenqa/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/jwtrainmain", breadcrumb: true },
      { title: 'Main', url: "/jwtrainmain/listenb?maintenance=1", breadcrumb: true },
      { title: 'Listening and Choice', breadcrumb: true }
    ],
  },
  {
    path: '/mexamlist',
    name: 'jwExamList',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwexamlist/index.vue'),
    meta: [
      { title: "icao_common.simulation", breadcrumb: true },
    ],
  },
  {
    path: '/mexam/listen',
    name: 'jwExamListenTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwexamlistentest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/mexamlist", breadcrumb: true },
      { title: 'CCAR-66 R3 for Maintenance License', breadcrumb: true }
    ],
  },
  {
    path: '/mexam/history',  /* 有历史记录按钮*/
    name: 'jwExamHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwexamhistory/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/mexamlist", breadcrumb: true },
      { title: 'na_common.history', breadcrumb: true }
    ],
  },
  {
    path: '/mexam/listen/score',
    name: 'jwExamListenTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwexamlistentestscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/mexamlist", breadcrumb: true },
      { title: 'CCAR-66 R3 for Maintenance License/Score', breadcrumb: true }
    ],
  },
  //短语和术语
  {
    path: '/mphraseortermin',
    name: 'jwphraseortermin',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwphraseortermin/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/mphraseortermin/books',
    name: 'jwphraseorterminbooks',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwphraseorterminbooks/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/mphraseortermin/card',
    name: 'jwphraseortermincard',
    component: () => import(/* webpackChunkName: "about" */ '../views/jwphraseortermincard/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },


































  /**
 * 空管路由部分
 */
  {
    path: '/aetscourse',
    name: 'kgcourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/kgcourse/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/aetscoursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  {
    path: '/aetscoursemain',
    name: 'kgcoursemain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/kgcoursemain/index.vue'),
    meta: [{ title: 'icao_common.selftaught', breadcrumb: true }]

  },
  {
    path: '/aetscoursemain/train',
    name: 'kgcoursetrain',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgcoursetrain/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/aetscoursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  //短语和术语
  {
    path: '/aetsphraseortermin',
    name: 'kgphraseortermin',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgphraseortermin/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/aetsphraseortermin/books',
    name: 'kgphraseorterminbooks',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgphraseorterminbooks/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/aetsphraseortermin/card',
    name: 'kgphraseortermincard',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgphraseortermincard/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  //训练 听力
  {
    path: '/aetstrainmain',
    name: 'kgtrainmain',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainmain/index.vue'),
    meta: [{ title: 'icao_common.specifictraining', breadcrumb: true }],
  },
  {
    path: '/aetstrainmain/listen',
    name: 'kgTrainListen',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainlisten/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listentothenmainidea', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/listen/select',
    name: 'kgTrainListenSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainlistenselect/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listentothenmainidea', url: "/aetstrainmain/listen", breadcrumb: true },
      { title: 'na_common.easy', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/listen/multipleselect',
    name: 'kgTrainListenMultipleSelect',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainlistenmultipleselect/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listentothenmainidea', url: "/aetstrainmain/listen", breadcrumb: true },
      { title: 'na_common.difficulty', breadcrumb: true }
    ],
  },




  // 简答题
  {
    path: '/aetstrainmain/short',
    name: 'kgTrainShort',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainshort/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listenandanswerthequestions', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/short/qa',
    name: 'kgTrainShortQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainshortqa/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listenandanswerthequestions', url: "/aetstrainmain/short", breadcrumb: true },
      { title: 'na_common.easy', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/short/multipleqa',
    name: 'kgTrainShortMultipleQa',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainshortmultipleqa/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listenandanswerthequestions', url: "/aetstrainmain/short", breadcrumb: true },
      { title: 'na_common.difficulty', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/short/qatest',
    name: 'kgTrainShortQaTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainshortqatest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listenandanswerthequestions', url: "/aetstrainmain/roleplayteststart", breadcrumb: true },
      { title: 'na_common.self-testing', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/shortqateststart',  /* 有历史记录按钮*/
    name: 'kgTrainShortQaTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainshortqateststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listenandanswerthequestions', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/short/qatest/score',
    name: 'kgTrainShortQaTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainshortqatestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'aets_common.listenandanswerthequestions', url: "/aetstrainmain/roleplayteststart", breadcrumb: true },
      { title: 'na_common.score', breadcrumb: true }
    ],
  },
    //训练故事复诵 路由
    {
      path: '/aetstrainmain/story',
      name: 'kgTrainStory',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainstory/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'icao_common.retellingstory', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/storytest',
      name: 'kgTrainStoryTest',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainstorytest/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'icao_common.retellingstoryselfstesting', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/storytest/score',
      name: 'kgTrainStoryTestScore',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainstorytestscore/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'icao_common.retellingstoryselfstestingscore', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/storyteststart',  /* 有历史记录按钮*/
      name: 'kgTrainStoryTestStart',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainstoryteststart/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'icao_common.retellingstoryselfstestingstart', breadcrumb: true }
      ],
    },
       //训练面试
       {
        path: '/aetstrainmain/interview',
        name: 'kgTrainInterview',
        component: () => import(/* webpackChunkName: "about" */ '../views/kgtraininterview/index.vue'),
        meta: [
          { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
          { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
        ],
      },
       {
        path: '/aetstrainmain/interview/train',
        name: 'kgTrainInterviewTrain',
        component: () => import(/* webpackChunkName: "about" */ '../views/kgtraininterviewtrain/index.vue'),
        meta: [
          { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
          { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
        ],
      },
    {
      path: '/aetstrainmain/interviewtest',
      name: 'kgTrainInterviewTest',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtraininterviewtest/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'icao_common.oralproficiencyinterviewselftesting', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/interviewteststart',  /* 有历史记录按钮*/
      name: 'kgTrainInterviewTestStart',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtraininterviewteststart/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'icao_common.oralproficiencyinterviewselftestingstart', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/interviewtest/score',
      name: 'kgTrainInterviewTestScore',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtraininterviewtestscore/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'icao_common.oralproficiencyinterviewselftestingscore', breadcrumb: true }
      ],
    },
      //情景
  {
    path: '/aetstrainmain/src',
    name: 'kgTrainSRC',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainsrc/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/src/roleplay',
    name: 'kgTrainSRCRolePlay',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainsrcroleplay/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication', url: "/aetstrainmain/src", breadcrumb: true },
      { title: 'icao_common.roleplayexcercises', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/src/roleplaytest',
    name: 'kgTrainSRCRolePlayTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainsrcroleplaytest/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftesting-start', url: "/aetstrainmain/roleplayteststart", breadcrumb: true },
      { title: 'icao_common.roleplayexcercisesselftesting', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/roleplayteststart',  /* 有历史记录按钮*/
    name: 'kgTrainSRCRolePlayTestStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainsrcroleplayteststart/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftesting-start', breadcrumb: true }
    ],
  },
  {
    path: '/aetstrainmain/src/roleplaytest/score',
    name: 'kgTrainSRCRolePlayTestScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainsrcroleplaytestscore/index.vue'),
    meta: [
      { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
      { title: 'icao_common.simulatingradiotelephonycommunication-selftestingscore', breadcrumb: true }
    ],
  },
  ///////////////////自测
  // 听力 自测和考试
    {
      path: '/aetstrainmain/listentest',
      name: 'kgTrainListenTest',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainlistentest/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'Listening Self-testing', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/listenteststart',  /* 有历史记录按钮*/
      name: 'kgTrainListenTestStart',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainlistenteststart/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'Listening Self-testing Start', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/listentest/score',
      name: 'kgTrainListenTestScore',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainlistentestscore/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: 'Listening Self-testing/score', breadcrumb: true }
      ],
    },
    {
      path: '/aetstrainmain/listentest/history/score',  /* 有历史记录按钮*/
      name: 'kgTrainListenTestHistoryScore',
      component: () => import(/* webpackChunkName: "about" */ '../views/kgtrainlistentesthistoryscore/index.vue'),
      meta: [
        { title: 'icao_common.specifictraining', url: "/aetstrainmain", breadcrumb: true },
        { title: "Listening Self-testing", url: "/aetstrainmain/listenteststart", breadcrumb: true },
        { title: "History", url: "/aetstrainmain/listentest/history", breadcrumb: true },
        { title: 'Score', breadcrumb: true }
      ],
    },



  //考试
  {
    path: '/aetsexamlist',
    name: 'kgExamList',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexamlist/index.vue'),
    meta: [
      { title: "icao_common.simulation", breadcrumb: true },
    ],
  },
  {
    path: '/aetsexamlist/testdevice',
    name: 'kgExamListTextDevice',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexamtestdevice/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/aetsexamlist", breadcrumb: true },
      { title: "Equipment Testing", breadcrumb: true },
    ],
  },
  {
    path: '/aetsexam/instructions',
    name: 'kgExamListInstructions',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexaminstructions/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
      { title: "INSTRUCATIONS", breadcrumb: true },
    ],
  },
  {
    path: '/aetsexam/listen',
    name: 'kgExamListenTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexamlisten/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/aetsexamlist", breadcrumb: true },
      { title: "icao_common.listeningcomprehension", breadcrumb: true }
    ],
  },
  {
    path: '/aetsexam/storytest',
    name: 'kgExamStoryTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexamstorytest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/aetsexamlist", breadcrumb: true },
      { title: 'Story Retelling', breadcrumb: true }
    ],
  },
  {
    path: '/aetsexam/shortqatest',
    name: 'kgExamShortQaTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexamshortqatest/index.vue'),
    meta: [
      { title: 'icao_common.simulation', url: "/aetsexamlist", breadcrumb: true },
      { title: 'Listen And Answer The Questions', breadcrumb: true }
    ],
  },
    {
    path: '/aetsexam/roleplaytest',
    name: 'kgExamRolePlayTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexamsrcroleplaytest/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/aetsexamlist", breadcrumb: true },
      { title: 'Interactive Simulation Test', breadcrumb: true }
    ],
  },
    {
    path: '/aetsexam/interview',
    name: 'kgExamInterview',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexaminterview/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/aetsexamlist", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },
  {
    path: '/exam/interview/score',
    name: 'kgExamInterviewScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/kgexaminterviewscore/index.vue'),
    meta: [
      { title: "icao_common.simulation", url: "/aetsexamlist", breadcrumb: true },
      { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
    ],
  },
  // {
  //   path: '/exam/listen/score',
  //   name: 'ExamListenTestScore',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examlistentestscore/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'Listening Comprehension/Score', breadcrumb: true }
  //   ],
  // },
  // {
  //   path: '/exam/repeatingtest',
  //   name: 'ExamRepeatingTest',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatingtest/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'Repeating Radiotelephony Sentences', breadcrumb: true }
  //   ],
  // },
  // {
  //   path: '/exam/repeatinghot',
  //   name: 'ExamRepeatingHot',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatinghot/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'Repeating Radiotelephony Sentences', breadcrumb: true }
  //   ],
  // },
  // {
  //   path: '/exam/repeatingtest/score',
  //   name: 'ExamRepeatingTestScore',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examrepeatingtestscore/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'Repeating Radiotelephony Sentences/Score', breadcrumb: true }
  //   ],
  // },
  // {
  //   path: '/exam/roleplaytest',
  //   name: 'ExamRolePlayTest',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examsrcroleplaytest/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'icao_common.roleplayexcercises', breadcrumb: true }
  //   ],
  // },
  // {
  //   path: '/exam/roleplaytest/score',
  //   name: 'ExamRolePlayTestScore',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examsrcroleplaytestscore/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'icao_common.roleplayexcercises', breadcrumb: true }
  //   ],
  // },

  // {
  //   path: '/exam/interview',
  //   name: 'ExamInterview',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examinterview/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
  //   ],
  // },

  // {
  //   path: '/exam/interview/score',
  //   name: 'ExamInterviewScore',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examinterviewscore/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'icao_common.oralproficiencyinterview', breadcrumb: true }
  //   ],
  // },
  // {
  //   path: '/exam/storytest',
  //   name: 'ExamStoryTest',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examstorytest/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'Retelling Story', breadcrumb: true }
  //   ],
  // },
  // {
  //   path: '/exam/storytest/score',
  //   name: 'ExamStoryTestScore',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/examstorytestscore/index.vue'),
  //   meta: [
  //     { title: "icao_common.simulation", url: "/examlist", breadcrumb: true },
  //     { title: 'Retelling Story/Score', breadcrumb: true }
  //   ],
  // },

  // 历史
  {
    path: '/aets/history',  /* 有历史记录按钮*/
    name: 'AetsHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/kghistorylist/index.vue'),
    meta: [
      { title: "na_common.history", breadcrumb: true }
    ],
  },
  {
    path: '/aets/historyscore',  /* 有历史记录按钮*/
    name: 'AetsHistoryScore',
    component: () => import(/* webpackChunkName: "about" */ '../views/kghistoryscore/index.vue'),
    meta: [
      { title: "na_common.history", url: "/aets/history", breadcrumb: true },
      { title: "na_common.score", breadcrumb: true }
    ],
  },






































  /**
 * 空乘路由部分
 */
  {
    path: '/cccourse',
    name: 'cccourse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cccourse/index.vue'),
    meta: [
      { title: "icao_common.selftaught", url: "/cccoursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  {
    path: '/cccoursemain',
    name: 'cccoursemain',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cccoursemain/index.vue'),
    meta: [{ title: 'icao_common.selftaught', breadcrumb: true }]

  },
  {
    path: '/cccoursemain/train',
    name: 'cccoursetrain',
    component: () => import(/* webpackChunkName: "about" */ '../views/cccoursetrain/index.vue'),
    meta: [
      { title: 'icao_common.selftaught', url: "/cccoursemain", breadcrumb: true },
      { title: 'na_common.course', breadcrumb: true }
    ],
  },
  //短语和术语
  {
    path: '/ccphraseortermin',
    name: 'ccphraseortermin',
    component: () => import(/* webpackChunkName: "about" */ '../views/ccphraseortermin/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/ccphraseortermin/books',
    name: 'ccphraseorterminbooks',
    component: () => import(/* webpackChunkName: "about" */ '../views/ccphraseorterminbooks/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },
  {
    path: '/ccphraseortermin/card',
    name: 'ccphraseortermincard',
    component: () => import(/* webpackChunkName: "about" */ '../views/ccphraseortermincard/index.vue'),
    meta: [{ title: 'icao_common.phraseologyterminology', breadcrumb: true }],
  },


]

const router = new VueRouter({
  routes
})

export default router
