import request from '@/utils/chatgptrequest'



export function speechtotext(url,data,headers) {
  return request({
    url: url+'audio/transcriptions',
    method: 'post',
    headers: headers,
    data
  })
}


export function saveUserTrain(data) {
  return request({
    url: '/icao/study/saveUserTrain',
    method: 'post',
    data
  })
}



export function GetStudyProgress(query) {
  return request({
    url: '/icao/listen.train/studyProgress',
    method: 'get',
    params: query
  })
}
