const state = {
   itemData: null,
}

const mutations = {
  SET_ITEMDATAS: (state, itemData) => {
    state.itemData = itemData
  },
}

const actions = {
  setitemData({ commit }, itemData) {
    commit('SET_ITEMDATAS', itemData)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
