const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  phone: state => state.user.phone,
  email: state => state.user.email,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  //会员VIP和试用状态
  fxVip: state => state.user.fxVip,
  fxVipTime: state => state.user.fxVipTime,
  jwVip: state => state.user.jwVip,
  jwVipTime: state => state.user.jwVipTime,
  kcVip: state => state.user.kcVip,
  kcVipTime: state => state.user.kcVipTime,
  kgVip: state => state.user.kgVip,
  kgVipTime: state => state.user.kgVipTime,
  trial: state => state.user.trial,
  edutoken: state => state.user.edutoken,
}
export default getters
