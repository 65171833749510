import request from '@/utils/request'

/**
 * 参数 status（非必填 1回答正确 2回答错误） 和 scene（非必填 阶段ID 1-9）
 * @param {*} query 
 * @returns 
 */
export function fetchList(query) {
  return request({
    url: '/icao/oral.train/index',
    method: 'get',
    params: query
  })
}


/**
 * 更新OSS ststoken
 * @param {*} query 
 * @returns 
 */
export function getStsInfo() {
  return request({
    url: '/icao/alists/getStsInfo',
    method: 'get',
  })
}



//题帽题上传记录
/**
 * 请求 post 参数 parent_id ( 题帽题主题id ) results 答题结果 （ json字符串格式如下 ）
二维数组[['question_id'=>1,'alioss_file'=>'http://www.baidu.com','result'=>[] ]]
      results: [{question_id: "1040",…}, {question_id: "1041",…}, {question_id: "1042",…}]
alioss_file: "https://icao.oss-cn-beijing.aliyuncs.com/upload/20210409/788d89fd953289acab5a89cdc6b313e2.mp3?OSSAccessKeyId=LTAI4FhKZ4tu6eD9z1ajBDUh&Expires=1617966550&Signature=rnoZjXmokGQbE%2FG2a%2Bo5NNmaqU0%3D"
question_id: "1040"
 * @param {*} data 
 * @returns 
 */
export function saveUserTmt(data) {
  return request({
    url: '/icao/study/saveUserTmt',
    method: 'post',
    data
  })
}

export function saveUserTmtAI(data) {
  return request({
    url: '/icao/study/saveUserTmt6',
    method: 'post',
    data
  })
}

export function saveUserTrain(data) {
  return request({
    url: '/icao/study/saveUserTrain',
    method: 'post',
    data
  })
}


//上传测试录音接口
export function UpRec(data) {
  return request({
    url: '/icao/ajax/voiceUp',
    method: 'post',
    data
  })
}

/**
 * 评分接口
 * @param {
 * aistreamtext: 实时识别文字
 * url: 音频url
 * qid: 小题id
 * rid:  本套题记录id
 * type: 1 训练 2 自测 3 模拟考试
 * } query 
 * @returns 
 */
export function voiceAsr(data) {
  return request({
    url: '/icao/Ajax/voiceAsr',
    method: 'post',
    data
  })
}

//本地socketio初始化接口
export function websocketinit(data) {
  return request({
    contentType: "json",   //这句是关键，修改入参格式
    url: 'https://192.168.1.9:8009/',
    method: 'post',
    data
  })
}


//本地socketio-创建新用户和获取用户id接口
export function getnewchatindex() {
  return request({
    contentType: "json",   //这句是关键，修改入参格式
    url: 'https://192.168.1.9:8009/get_new_user_conversation_index',
    method: 'get',
    
  })
}


//录音题评分
/**
 * 
 * 目前适用情况900句评分，900句自测，题帽题评分
传输方式 post 参数 result （必需，数组格式）paperid（非必需，试卷ID，仅自测时填）
 * @param {*} data 
 * @returns 
 */
export function TestGradeByAi (data) {
  return request({
    url: '/icao/exam/savePractice',
    method: 'post',
    data
  })
}


/**
 * situation  5  情景
 * @param {*} query 
 * @returns 
 */
export function fetchListTest(query) {
  return request({
    url: '/icao/exam/createPaper',
    method: 'get',
    params: query
  })
}


/**
 * beginOral  训练生成开始记录
 * @param {
 * 
 * } query 
 * @returns {rid}
 */
 export function beginOral() {
  return request({
    url: '/icao/ajax/beginRecord',
    method: 'get',
  
  })
}


// 面试答题进度
export function GetOralStudyProgress(query) {
  return request({
    url: '/icao/oral.train/studyProgress',
    method: 'get',
    params: query
  })
}


export function delTrainRecord(data) {
  return request({
    url: '/icao/study/delTrainRecord',
    method: 'post',
    data
  })
}
