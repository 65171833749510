const state = {
    flag: null,
    minutes: '00', // 分
    seconds: '00', // 秒
    status: '',
}

const mutations = {
    //var maxtime = 60 * 60; //一个小时，按秒计算，自己调整!   
    START_HANDLER: (state,maxtime) => {
        state.flag = setInterval(() => {
            if (maxtime >= 0) {
                if (maxtime >= 0) {
                    state.minutes = Math.floor(maxtime / 60);
                    state.seconds = Math.floor(maxtime % 60);
                        --maxtime;
                } else{
                    state.flag = clearInterval(state.flag)
                    state.status = 'shan'
                }
            }else{
                state.flag = clearInterval(state.flag)
                state.status = 'shan'
            }
        }, 1000)
    },
    // 暂停计时
    END_HANDLER: (state) => {
        state.flag = clearInterval(state.flag)
        state.status = 'stop'
    }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
