<template>
  <div>
     <HomeHeader/>
    <div style="width: 1200px; margin: 0 auto" class="banner">

      <div class="hometitle">
        <div class="ceng">
          <span>关于我们</span>
        </div>
      </div>
      <div style="width: 100%">
        <p style="line-height: 30px; text-indent: 2em">
          航科在线（天津）网络技有限公司位于天津市东丽区航空商务区，是一家专注于航空产品研发和服务的国家科技型中小企业，主营业务是民航技术与设备的研发、智能交通系统应用开发和应用软件开发。公司面向民航领域一线需求，瞄准行业在信息技术、软件产品和核心装备等“卡脖子”问题，联合行业院校和科研机构共同研发国产化的高科技产品。
        </p>
       
      </div>
    </div>
    <HomeFooter />
  </div>
</template>

<script>
import HomeHeader from '@/components/HomeHeader'
import HomeFooter from '@/components/HomeFooter'
export default {
  name: 'Home',
  components: {HomeHeader,HomeFooter},
  data() {
    return {
      
    }
  },
  created() {
    //window.location.href = '/home.html'
  },
  methods: {
    
  },
}
</script>
<style  lang="scss" scoped>
body a {
  text-decoration: none;
}
.hometitle {
  height: 40px;
  background: url('~@/assets/hometitlebg01.svg') no-repeat center center;
  font-size: 28px;
  text-align: center;
  margin: 50px 0;
  .ceng {
    background-color: white;
    margin: 0 auto;
    width: 150px;
  }
}
</style>
