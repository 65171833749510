import request from '@/utils/request'


/**
 * 获取介绍页内容
 * @param {*} query 
 * @returns 
 */
export function fetchList() {
  return request({
    // url: 'https://localhost:8080/jsondata/home.json',
    url: 'https://icao.avstedu.com/jsondata/home.json',
    method: 'get',
  })
}


/**
 * 获取常见问题
 * @param {*} query 
 * @returns 
 */
export function getGeneralQuestion() {
  return request({
    url: '/icao/regulations.Problem/index',
    method: 'get',
  })
}


/**
 * 获取介绍页课程内容
 * @param {*} query 
 * @returns 
 */
export function CoursefetchList(cateid) {
  if (cateid == 0) {
    return request({
      url: '/icao/course.study/front',
      method: 'get',
    })
  }else if(cateid == 1){
    return request({
      url: '/maintenance/course.study/front',
      method: 'get',
    })
  }else if(cateid == 2){
    return request({
      url: '/aets/course.study/front',
      method: 'get',
    })
  }else if(cateid == 3){
    return request({
      url: '/cce/course.study/front',
      method: 'get',
    })
  }
}



//message
export function Feedback(data) {
  return request({
    url: '/icao/message/feedback',
    method: 'post',
    data
  })
}


//底部版权声明
export function getFooterSetting() {
  return request({
    url: '/icao/version/getSetting',
    method: 'get',
  })
}
