<template>
  <div class="login-container">
    <div v-if='message=="repeatlogin"' style="background:red;text-align:center;color:white;font-size:18px;padding:20px">
        您的账号已在其它设备登录！
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">民航英语交互式学习与测试系统</h3>
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="手机号码"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>

      
      <el-form-item  prop="phoneCode" class="pr" v-if="loginstate == 'code'">
              <el-input style="margin:3px 0px 2px 0;" placeholder="短信验证码" name="phoneCode" ref="phoneCode" v-model="loginForm.phoneCode"></el-input>
              <button @click.prevent="getCode()"  class="code-btn" :disabled="!show">
                  <span v-show="show">获取验证码</span>
                  <span v-show="!show" class="count">{{count}} s</span>
              </button>
      </el-form-item>

      <el-tooltip
        v-else-if="loginstate == 'password'"
        v-model="capsTooltip"
        content="Caps lock is On"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
      </el-tooltip>
        
          
         


      <el-button
        :loading="loading"
        type="primary"
        style="width: 100%; margin-bottom: 30px"
        @click.native.prevent="handleLogin"
        >登录</el-button
      >

       <div style="position: absolute; bottom:-10px;" v-if="isbrower == false">
        <div class="tips" style="height: 70px;margin-right:150px;background:red;padding:5px;">
          * 您的浏览器并不符合系统要求！本系统仅支持 Chrome、Edge(Chrome内核)、360安全浏览器（极速模式）, 并且chrome内核版本应该在 （90.0.0）以上。如果使用其他非支持浏览器，不能保证所有功能完整运行。
        </div>
      </div>
    </el-form>
    <div class="login-formceng"></div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'
import { getCode,getAvsteduLogin,getAvsteduTokenLogin } from '@/api/user'
import { verifytelphone, validEmail } from '@/utils/validate'

export default {
  name: 'Login',
  components: {  },
  computed: {
    ...mapGetters(['token', 'roles','edutoken']),
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error('电话号码不能为空！'))
      } else if (value.length < 3) {
        callback(new Error('输入长度必须大于3位！'))
      } else if (value.length == 11) {
        if (!verifytelphone(value)) {
          callback(new Error('输入手机号码错误！'))
        } else {
          callback()
        }
      } 
      // else if (!validEmail(value)) {
      //   callback(new Error('输入邮箱格式错误！'))
      // } 
      else {
        callback(new Error('输入手机号码错误！'))
      }
    }

    const validatephonecode = (rule, value, callback) => {
      if (value.length != 6) {
        callback(new Error('输入长度必须为6位！'))
      } else {
        callback()
      }
    }

    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('输入密码不能少于6位！'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: '',
        phoneCode: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', validator: validateUsername },
        ],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword },
        ],
        phoneCode: [
          { required: true, trigger: 'blur', validator: validatephonecode },
        ],
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      isbrower: true,
      message: "",

      loginstate: 'password',
       show: true,
      timer: null,
      count: 60,
      checked: false,
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
           this.message = query.message
          console.log(this.redirect)
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true,
    },
  },
  created() {
    // window.addEventListener('storage', this.afterQRScan)
    console.log('role:' + this.roles)
    var sBrowser,sUsrAg = navigator.userAgent;
    console.log(sUsrAg)
      if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
      } else if (sUsrAg.indexOf("Opera") > 1 || sUsrAg.indexOf("OPR") >-1) {
        sBrowser = "Opera";
      } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        }
      else if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = "Firefox";
        //屏蔽火狐浏览器，打开时自动屏蔽空白
      }else if(sUsrAg.indexOf('Chrome')> -1){
sBrowser = "Chrome";
      }else if(sUsrAg.indexOf('Safari')> -1){
sBrowser = "Safari";
      }else if(sUsrAg.indexOf('Trident')> -1){
sBrowser = "Microsoft Edge";
      }
      
      if(sBrowser != 'Chrome'){
        this.isbrower = false
      }else{
        // Chrome/113.0.0.0 获取chrome浏览器版本号，判断是否大于90
        console.log(parseInt(sUsrAg.split('Chrome/')[1].split('.')[0]))
        if(parseInt(sUsrAg.split('Chrome/')[1].split('.')[0]) < 90){
          this.isbrower = false
        }else{
this.isbrower = true
        }
        
      }

  },
  mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
        // 获取短信验证码
    getCode() {
      if (!verifytelphone(this.loginForm.username)) {
          this.$message.error('输入手机号码错误！')
          return
        } 
       //axios请求
      var form = new FormData()
      form.append('phone', this.loginForm.username)
      getCode(form).then((response) => {
        
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      }).catch((error) => {
        this.$message.error('验证码发送失败')
      })
      
    },
    switchLoginstate() {
      if (this.loginstate == 'password') {
        this.loginstate = 'code'
      } else {
        this.loginstate = 'password'
      }
      return
    },
    tryuselogin() {
       window.location.href = 'https://icaotest.avstedu.com/#/coursemain';
    },

    // tryuselogin() {
    //   this.loginForm.username = 'sample@qq.com'
    //   this.loginForm.password = '123456'
    //   this.loading = true
    //   this.$store
    //     .dispatch('user/login', this.loginForm)
    //     .then(() => {
    //       this.$router.push({
    //         path: '/coursemain' || '/',
    //         query: this.otherQuery,
    //       })
    //       this.loading = false
    //     })
    //     .catch(() => {
    //       this.loading = false
    //     })
    // },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      //this.$router.push({ path: '/coursemain' || '/', query: this.otherQuery })
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if(this.loginstate == "password"){
            this.loginForm.phoneCode = ""
          }else if(this.loginstate == "code"){
            this.loginForm.password = ""
          }
          this.$store
            .dispatch('user/login', this.loginForm)
            .then(() => {
              console.log(this.redirect)
              if (this.redirect != undefined) {
                this.$router.push({
                  path: this.redirect,
                  query: this.otherQuery,
                })
              } else {
                  this.$router.push({
                    path: '/hkopi',
                    query: this.otherQuery,
                  })
              }

              this.loading = false

            })
            .catch(() => {
              this.loading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }
  },
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-form-item__content {
    background: $bg;
  }

  .el-input {
    display: inline-block;
    height: 47px;
    width: 80%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bimg: '~@/assets/loginb2.png';
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

/* 短信验证码css */
.pr {
position: relative;

}
.code-btn {
    width: 100px;
    height: 47px;
    position: absolute;
    top: 2px;
    right: 0px;
    z-index: 222;
    /* color: #ef8466; */
    font-size: 14px;
    border: 0px;
    /* border-left: 1px solid #bababa; */
    padding-left: 10px;
    background-color: #fff;
    cursor: pointer;

}
.login-container {
  min-height: 100%;
  width: 100%;
  background-image: url($bimg);
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    z-index: 100;
  }

  .login-formceng {
    position: relative;
    width: 520px;
    height: 350px;
    top: -360px;
    background-color: #2d3a4b;
    opacity: 0.7;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    z-index: 1;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 100px;
    bottom: 6px;
  }

  .try-button {
    position: absolute;
    right: 0px;
    bottom: 6px;
  }
  .messagetopasswordswitch{
    padding-bottom:20px;float:right;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
</style>
