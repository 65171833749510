import { login, logout, getInfo, signup } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  phone: '',
  email: '',
  //会员VIP和试用状态
  fxVip: '',
  fxVipTime: '',
  jwVip: '',
  jwVipTime: '',
  kcVip: '',
  kcVipTime: '',
  kgVip: '',
  kgVipTime: '',
  trial: '',
  edutoken: '',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  //会员VIP和试用状态
  SET_FXVIP: (state, fxVip) => {
    state.fxVip = fxVip
  },
  SET_FXVIPTIME: (state, fxVipTime) => {
    state.fxVipTime = fxVipTime
  },
  SET_JWVIP: (state, jwVip) => {
    state.jwVip = jwVip
  },
  SET_JWVIPTIME: (state, jwVipTime) => {
    state.jwVipTime = jwVipTime
  },
  SET_KCVIP: (state, kcVip) => {
    state.kcVip = kcVip
  },
  SET_KCVIPTIME: (state, kcVipTime) => {
    state.kcVipTime = kcVipTime
  },
  SET_KGVIP: (state, kgVip) => {
    state.kgVip = kgVip
  },
  SET_KGVIPTIME: (state, kgVipTime) => {
    state.kgVipTime = kgVipTime
  },
  SET_TRIAL: (state, trial) => {
    state.trial = trial
  },
  SET_EDUTOKEN: (state, edutoken) => {
    state.edutoken = edutoken
  },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, phoneCode } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password,code: phoneCode }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        commit('SET_ROLES', data.role)
        commit('SET_EDUTOKEN', data.logstr)
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

    // user login
  signup({ commit }, userInfo) {
      const { username, password,phoneCode } = userInfo
      return new Promise((resolve, reject) => {
        signup({ username: username.trim(), password: password,code: phoneCode }).then(response => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          // console.log("singup_data_logstr:"+data.logstr)
          commit('SET_EDUTOKEN', data.logstr)
          setToken(data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        window.console.log(data)
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        //const { roles, name, avatar, introduction } = data
        const {  uname,phone,email,fxVip,fxVipTime,jwVip,jwVipTime,kcVip,kcVipTime,kgVip,kgVipTime,trial} = data
        const   roles  = data.role
        // roles must be a non-empty array
       if (!roles || roles.length <= 0) {
         reject('getInfo: roles must be a non-null array!')
       }
         
        commit('SET_ROLES', roles)
        commit('SET_NAME', uname)
        commit('SET_PHONE', phone)
        commit('SET_EMAIL', email)

        //会员VIP和试用状态
        console.log('fxVip',fxVip)
        commit('SET_FXVIP', fxVip)
        commit('SET_FXVIPTIME', fxVipTime)
        commit('SET_JWVIP', jwVip)
        commit('SET_JWVIPTIME', jwVipTime)
        commit('SET_KCVIP', kcVip)
        commit('SET_KCVIPTIME', kcVipTime)
        commit('SET_KGVIP', kgVip)
        commit('SET_KGVIPTIME', kgVipTime)
        commit('SET_TRIAL', trial)
       
        //commit('SET_AVATAR', avatar)
        //commit('SET_INTRODUCTION', introduction)
        resolve(data)
      }).catch(error => {
        window.console.log('wrror --- error')
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_NAME', '')

         //会员VIP和试用状态
         commit('SET_FXVIP', '')
         commit('SET_FXVIPTIME', '')
         commit('SET_JWVIP', '')
         commit('SET_JWVIPTIME', '')
         commit('SET_KCVIP', '')
         commit('SET_KCVIPTIME', '')
         commit('SET_KGVIP', '')
         commit('SET_KGVIPTIME', '')
         commit('SET_TRIAL', '')

        removeToken()
        //resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      // }).catch(error => {
      //   reject(error)
       })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
