<template>
  <div class="postimedown">
    <span
      class="blink"
      style="font-weight: 700"
      v-if="
      status == 'shan'
      "
    >
      Countdown : You have timed out
    </span>
    <span style="font-weight: 700" v-else>
      Countdown: {{ localtimer.minutes }} minutes
      {{ localtimer.seconds }} seconds
    </span>
  </div>
</template>
<script>
export default {
  name: 'TimerCountdown',
  props: {
    inputstartstatus: {},
    initminutes: {},
    initseconds: {},
    maxtime: Number
  },
  watch: {
    inputstartstatus(newVal) {
      this.startstatus = newVal
      window.console.log('开始状态')
      if (newVal) {
        this.$store.commit('timercountdown/START_HANDLER',this.maxtime)
        this.localflag = window.setInterval(() => {
          this.localtimer.minutes = this.$store.state.timercountdown.minutes
          this.localtimer.seconds = this.$store.state.timercountdown.seconds
          this.status = this.$store.state.timercountdown.status
          //window.console.log(this.localtimer.one+ '==='+ this.localtimer.two + '===' + this.localtimer.three)
        }, 1000)
      }
    },
    initminutes(newVal) {
      window.console.log(newVal)
      this.localtimer.minutes = newVal
    },
    initseconds(newVal) {
      window.console.log(newVal)
      this.localtimer.seconds = newVal
    },
  },
  data() {
    return {
      startstatus: false,
      localflag: null,
      localtimer: {
        minutes: 0, // 分
        seconds: 0, // 秒
      },
      status: '',
    }
  },
  created() {
    this.localtimer.minutes = this.initminutes
    this.localtimer.seconds = this.initseconds
    if (this.startstatus) {
      this.localflag = window.setInterval(() => {
        this.localtimer.minutes = this.$store.state.timercountdown.minutes
        this.localtimer.seconds = this.$store.state.timercountdown.seconds
        this.status = this.$store.state.timercountdown.status
        //window.console.log(this.localtimer.one+ '==='+ this.localtimer.two + '===' + this.localtimer.three)
      }, 1000)
    }
  },
}
</script>
<style lang="scss" scoped>
.postimedown {
  position: absolute;
  top: -65px;

  width: 300px;
  background-color: rgba(242, 242, 242, 1);
  text-align: center;
  padding: 20px 0px;
}
/* 定义keyframe动画，命名为blink */
@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 添加兼容性前缀 */
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* 定义blink类*/
.blink {
  color: #dd4814;
  animation: blink 1s linear infinite;
  /* 其它浏览器兼容性前缀 */
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  -ms-animation: blink 1s linear infinite;
  -o-animation: blink 1s linear infinite;
}
</style>