import request from '@/utils/request'
import { v4 as uuidv4 } from 'uuid'


/**
 * 更新OSS ststoken
 * @param {*} query 
 * @returns 
 */
export function getStsInfo() {
  return request({
    url: '/icao/alists/getStsInfo',
    method: 'get',
  })
}



/**
 * 初始化 AliOSS
 * @param {*} query 
 * @returns 
 */
export async function InitAliOss() {
  const OSS = require('ali-oss')
 var resinfo = await getStsInfo()
    var aliossclient = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: 'oss-cn-huhehaote',
      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: resinfo.data.AccessKeyId,
      accessKeySecret: resinfo.data.AccessKeySecret,
      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: resinfo.data.SecurityToken,
      refreshSTSToken: async () => {
        // 向您搭建的STS服务获取临时访问凭证。
        const info = await getStsInfo()
        console.log(info)
        return {
          accessKeyId: info.data.AccessKeyId,
          accessKeySecret: info.data.AccessKeySecret,
          stsToken: info.data.SecurityToken,
        }
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 300000,
      // 填写Bucket名称。
      bucket: 'icaos',
    })
    return aliossclient

}

export function setOssPathAndFilename(){
  var data= new Date()
  var Year = data.getFullYear()
  console.log((data.getMonth()+1).length)
  var Month = parseInt(data.getMonth()+1) >= 10 ? data.getMonth()+1 : '0'+(data.getMonth()+1)
  var Day = parseInt(data.getDate()) >= 10 ? data.getDate() : '0'+data.getDate()
  return  'upload/'+Year+Month+ Day+'/'+uuidv4()+'.wav';
}



