<template>
  <el-row>
    <div class="na_m_top">
      <el-container class="na_m_container">
        <el-main v-if="listData != null">
          <div style="max-width: 1024px; margin: 0px auto">
            <el-row
              style="
                text-align: center;
                line-height: 60px;
                height: 40px;
                margin-top: 20px;
              "
            >
              <div style="clear: both"></div>
            </el-row>

            <el-row class="src-content">
              <el-col :span="16" >
                <div class="src-img" style="padding:10px">
                  <!-- <img v-if="currentRow != null" :src="currentRow.img_url" /> -->
                  <p>（本系统支持chrome、edge浏览器）</p>
                  <p>录音准备：</p>
                  <p>1、确保浏览器已经开启了录音功能。(未开启，请根据浏览器左上角提示，点击['允许'])</p>
                  <p>2、确保您已准备了相关录音设备。</p>

                  <p>使用流程：</p>
                  <p>1、点击['start interview'] 按钮，考官会主动开始面试，并进行提问对话。</p>
                  <p>2、考官提问完成后，系统会自动开始录音，并在右下角显示录音倒计时。</p>
                  <p>3、考生回答问题后，请点击['Next']结束回答，进入下一轮对话，或等待倒计时结束后，进入下一轮对话。
                  <p>4、每三轮对话，会给出相应的分析和分数。</p>
                  <p>5、右下角出现finish, 代表本轮对话结束。</p>
                  <p>
                    （注意：请不要关闭浏览器，以免影响录音。如果出现问题，请尝试点击['try again']按钮,继续对话，或联系管理员。）
                  </p>
                </div>
              </el-col>
              <el-col :span="8" style="height: 100%">
                <div class="src-pb">
                  <div>
                    <TimerCountdown
                      :inputstartstatus="currnetStatus != 'firststop'"
                      initminutes="15"
                      initseconds="0"
                      :maxtime="900"
                    ></TimerCountdown>
                  </div>

                  <div
                    v-if="
                      currnetStatus == 'firststop' || currnetStatus == 'stop'
                    "
                  >
                    <div class="headimg">
                      <div>
                        <!-- Interviewer  -->
                            Assessor
                        </div>
                      <img
                        v-if="currentRow != null"
                        class="imgtx"
                        style="width: 160px; height: 160px"
                        :src="currentRow.interviewer"
                      />
                    </div>
                    <div
                      class="headimg"
                      style="padding-bottom: 10px; padding-top: 100px"
                    >
                      <div>Interviewee</div>
                      <!--<i class="el-icon-user-solid"></i>-->
                      <div
                        v-if="
                          currnetStatus == 'stop' &&
                          !popupvisable &&
                          EverythingEnd == false &&
                          trybuttonstatus != 'speechtotexterror'
                        "
                        style="padding-top: 50px; color: #409eff"
                      >
                        <i class="el-icon-upload" style="font-size: 50px"></i>
                        <br />
                        {{ $t('na_common.audioisbeinguploaded') }}
                      </div>
                      <img
                        v-if="currnetStatus == 'rec'"
                        src="~@/assets/microphone.gif"
                        style="width: 50px"
                      />
                    </div>
                  </div>

                  <div v-if="currnetStatus == 'play'">
                    <div class="headimg active">
                      <div>Interviewer</div>
                      <img
                        class="imgtx"
                        style="width: 160px; height: 160px"
                        :src="currentRow.interviewer"
                      />
                      <img
                        src="~@/assets/microphone.gif"
                        style="
                          width: 30px;
                          position: absolute;
                          top: 150px;
                          right: 90px;
                        "
                      />
                    </div>
                    <div
                      class="headimg"
                      style="padding-bottom: 10px; padding-top: 100px"
                    >
                      <div>Interviewee</div>
                      <!-- <i class="el-icon-user-solid"></i>-->
                      <img
                        v-if="currnetStatus == 'rec'"
                        src="~@/assets/microphone.gif"
                        style="width: 50px"
                      />
                    </div>
                  </div>
                  <div v-if="currnetStatus == 'rec'">
                    <div class="headimg">
                      <div>Interviewer</div>
                      <img
                        class="imgtx"
                        style="width: 160px; height: 160px"
                        :src="currentRow.interviewer"
                      />
                    </div>
                    <div
                      class="headimg active"
                      style="padding-bottom: 10px; padding-top: 100px"
                    >
                      <div>Interviewee</div>
                      <!-- <i class="el-icon-user-solid"></i>-->
                      <img
                        v-if="currnetStatus == 'rec'"
                        src="~@/assets/microphone.gif"
                        style="width: 50px"
                      />
                    </div>
                    <!---录音-->
                    <div style="text-align: center; width: 100%">
                      <div style="position: relative">
                        <div
                          style="
                            height: 100px;
                            width: 200px;
                            position: absolute;
                            top: 20px;
                            left: 50%;
                            margin-left: -100px;
                            transform: translate（ -50%，0 ）;
                            box-sizing: border-box;
                            display: inline-block;
                            vertical-align: bottom;
                          "
                          class="ctrlProcessWaveischeck"
                        ></div>
                      </div>
                      <div style="position: relative; z-index: 900">
                        <el-button
                          type="primary"
                          @click="ChildQuestionEnd"
                          plain
                          ><!--Finish Recording-->
                          Next</el-button
                        >
                      </div>
                      Recording remain {{ currentcountdown }} seconds
                    </div>
                  </div>

                  <el-row style="text-align: center; margin-top: 90px">
                    <div
                      v-if="currnetStatus == 'firststop'"
                      style="z-index: 900"
                    >
                      <el-button type="primary" @click="startinterview()" plain
                        >Start Interview</el-button
                      >
                    </div>
                    <div v-if="popupvisable" style="z-index: 900">
                      <el-button
                        type="primary"
                       @click="Closesubmitquestion()"
                        plain
                        >Close Interview</el-button
                      >
                       
                    </div>
                    <div v-if="EverythingEnd">finished</div>
                  </el-row>


                   <el-row style="text-align: center;">
                    <div
                      v-if="trybuttonstatus == 'speechtotexterror'"
                      style="z-index: 900"
                    >
                      <el-button type="primary" @click="oneuploadrec(currentblob)" plain
                        >Try Again</el-button
                      >
                    </div>
                     <div
                      v-if="trybuttonstatus == 'scoreerror'"
                      style="z-index: 900"
                    >
                      <el-button type="primary" @click="NextChildQuestion()" plain
                        >Try Again</el-button
                      >
                    </div>
                     <div
                      v-if="trybuttonstatus == 'hcaudioerror'"
                      style="z-index: 900"
                    >
                      <el-button type="primary" @click="speechEvent(tmpspeechtext)" plain
                        >Try Again</el-button
                      >
                    </div>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
       
        </el-main>
      </el-container>
     
               
   <el-table :data="hisdata" style="width: 100%" :cell-style="{verticalAlign: 'top'}" :header-cell-style="{backgroundColor: '#f2f2f2'}">
        <el-table-column type="index" width="50" label="阶段">
           <template  slot-scope="scope">
              <!-- 标号 圆形div中间有一个数字 -->
              <div style="margin-top: 20px;">
              <div class="index" style="width: 30px; height: 30px; border-radius: 50%; background-color: #409eff; color: #fff; text-align: center; line-height: 30px; margin: 0 auto;">{{ scope.$index+1 }}</div>
              </div>

           </template>
        </el-table-column>
        <el-table-column prop="content" label="问答"> 
           <template  slot-scope="scope">

               <div class="grid-content bg-purple-dark" v-for="(hisitem,index) in scope.row.content" :key="index">
                       <div v-if="hisitem.type == 'question'">
                          <div class="qtit" style="color: #409eff; font-weight: 700; ">Q {{ hisitem.id }}: {{ hisitem.content }}</div>
                       </div>
                        <div v-if="hisitem.type == 'answer'">
                            <div style="color: #67c23a; ">answer {{ hisitem.id }}: {{ hisitem.content }}</div>
                        </div>
               </div>
              
           </template>

        </el-table-column>
       
        <el-table-column prop="score" label="分数">
           <template slot-scope="scope">
            <el-row>
              <el-col :span="24" >
                <div v-html="scope.row.score"></div>
              </el-col>
            </el-row>
           </template>
        </el-table-column>
      </el-table>
      
    </div>
  </el-row>
</template>

<script>
import { fetchListTest, voiceAsr } from '@/api/interview'
import { getMessage } from '@/api/head'
import { removeToken } from '@/utils/auth'
import { speechtotext } from '@/api/chatgpt'
import TimerCountdown from '@/components/timercountdown'
import { InitAliOss, setOssPathAndFilename } from '@/api/alioss'

//录音功能：
//加载必须要的core，demo简化起见采用的直接加载类库，实际使用时应当采用异步按需加载
import Recorder from 'recorder-core'
//需要使用到的音频格式编码引擎的js文件统统加载进来，这些引擎文件会比较大
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
import 'recorder-core/src/engine/wav'
//可选的扩展
import 'recorder-core/src/extensions/waveview'
import CryptoJS from 'crypto-js'
//end 录音功能
export default {
  name: 'TrainSRCRolePlay',
  components: { TimerCountdown },
  data() {
    return {
      listQuery: {
        situation: 5,
        paperid: 0,
        currnetIndex: 0,
        CurrnetChildIndex: 0,
      },
      total: 0,
      listData: [],

      paperid: 0,
      currnetStatus: 'firststop', //play 播放，rec 录音,stop, firststop 第一次
      currentRow: null,

      //音频检测报错
      recaudioischeck: true,

      //倒计时,计时对象
      interval: null,
      currentcountdown: 0, //当前录音倒计时

      //录音功能
      Rec: Recorder,
      type: 'mp3',
      bitRate: 16,
      sampleRate: 16000,
      rec: 0,
      duration: 0,
      powerLevel: 0,
      recOpenDialogShow: 0,
      logs: [],
      currentblob: null,
      //end 录音功能

      //弹出答题分析总结
      summaryDialogVisible: false,
      EverythingEnd: false,
      value: 4.5,

      popupvisable: false,

      chatgptmessage: [],
      chatgptcurrentitemlength: 1,
      chatgptforitemnum: 2,

      downSocket: null,
      voiceSocket: null,
      speechQuene: [],
      sourceBuffer: null,
      speechPushing: false,
      downQuene: {},
      edgeTTSURL:
        'wss://speech.platform.bing.com/consumer/speech/synthesize/readaloud/edge/v1?trustedclienttoken=6A5AA1D4EAFF4E9FB37E23D68491D6F4',
      supportMSE: !!window.MediaSource, // 是否支持MSE（除了ios应该都支持）
      voiceIns: new Audio(),
      voiceMIME: 'audio/mpeg',
      mediaSource: new MediaSource(),
      hisdata: [],
      trybuttonstatus: '',

      qa_qianzhui: ["let's start with the question.", "next question.", "Let's move on to the next question.","Thank you for your answer, let's continue. ","let's start with the next question.","Understood. Continue listening to the question.","I appreciate your insights. Now, ponder on this:","Good response. Let's explore another angle:","That's an good point. How about we consider this?","I value your input. What are your thoughts on the following?","Great, let's delve into a different topic:","Can we touch on this subject?","Now that we've discussed that, I'm curious about something else:","Let's shift gears for a moment. ","Let's navigate to a new topic:","Considering your last answer, I'd like to follow up with:","Thanks for sharing your thoughts. Now, let's discuss this:","Let's keep the momentum going. What about this situation?"],

      // 语音合成发音错词替换
      ttserrorword: {
        "PEPEC": "PIPEC",
        "TCAS": "T-CAS",
        "RNAV": "R-nev",
        "Xi'an": "Xi An",
        "Xianyang": "Xian Yang",
        "SID" : "S I D",
        "LILING": "Li Ling",
      },

      // 敏感词替换
      sensetiveword: {
        "chatgpt": "assessor",
        "openai": "hangke ai",
        "FMSA" : "FMC"
      },

      fetchtimeout: 60000,
       
      hisitems : [], //历史记录单个选项
      tmpspeechtext : "",
      gpt_base_urls : [
        {url:'https://oa.api2d.net/v1/',apikey:'fk218851-1gJ1jNFR6IBUeC8UQ0Axiq3UTFcjE5NX'},
        {url:'https://oa.f2api.com/v1/',apikey:'sk-f2EvU4auahAa47xyinTJNEOMZLH06jlo0VuMZAuMvi7m3J9p'},
        
        ],
      kimi_base_urls : {url:'https://api.moonshot.cn/v1/',apikey:'sk-32Sv9OwILYystluDkbrJLk38ZwrE2uSAEBBXSx16Uuh0WMIS'},
      // https://oa.api2d.net和https://openai.api2d.net/v1/
      curent_gpt_base_url : 0,


    }
  },
  mounted() {
    var This = this
  },
  created() {
    var This = this
    //初始化 倒计时
    this.$store.commit('timercountdown/END_HANDLER')

    ///// OSS 对象存储 初始化 //////
    // InitAliOss().then((aliossclient)=>{
    //        This.aliossclient = aliossclient
    // })

    //this.listQuery.paperid = this.$route.query.paperid;
    this.getList()
    //检测是否开启了浏览器录音功能,如果没有，则启动浏览器开启提示
   
    navigator.mediaDevices.getUserMedia({ audio: true }).then(function(stream) { // 用户同意，可以开始录音处理
      console.log('录音流:', stream);
    }).catch(function(err) {  
      console.error('无法获取麦克风权限:', err);
      alert('请允许访问麦克风以开始录音。');
    });

    getMessage().then((res) => {
      console.log(res.message)
      if(res.message == "您的账号权限不足！"){
        removeToken()
        this.$store.dispatch('user/logout')
        this.$router.push(`/loginhk`)
        return 
      }
    })
      
  },
  destroyed() {
    console.log('destroyed')
    window.clearInterval(this.interval) //清空倒计时
    this.recStop()
    this.endSpeak() //结束语音合成
  },
  methods: {
    //替换敏感词 ,不区分大小写
    replaceSensetiveWord(str) {
      var This = this
      var newstr = str
      for (var key in This.sensetiveword) {
        var reg = new RegExp(key, 'ig')
        newstr = newstr.replace(reg, This.sensetiveword[key])
      }
      return newstr
    },
    // 发音修正，不区分大小写
    replaceTTSWord(str) {
      var This = this
      var newstr = str
      for (var key in This.ttserrorword) {
        var reg = new RegExp(key, 'ig') // 不区分大小写
        newstr = newstr.replace(reg, This.ttserrorword[key])
      }
      return newstr
    },
    
    //开始面试按钮
    startinterview() {
      // this.rid = null
      this.currnetStatus = 'play'
       // 将句子中的敏感词替换
      this.initcurrentmessage(
        "let's start with the first question. ",
        this.currentRow.children[this.listQuery.CurrnetChildIndex].title,
        1
      )
     
this.tmpspeechtext = "let's start with the first question. " +
          this.replaceTTSWord(this.currentRow.children[this.listQuery.CurrnetChildIndex].title)
      this.speechEvent(
        "let's start with the first question. " +
          this.replaceTTSWord(this.currentRow.children[this.listQuery.CurrnetChildIndex].title)
      )
    },

    ///文字转语音/////////////////////////////////////////////////////////////////
    handleUpdateEnd() {
      console.log('updateend event fired')
      // 在这里处理 onupdateend 事件
    },
    texttospeech() {},
    initSocket() {
      var This = this
      return new Promise((res, rej) => {
        if (!This.voiceSocket || This.voiceSocket.readyState > 1) {
          This.voiceSocket = new WebSocket(This.edgeTTSURL)
          This.voiceSocket.binaryType = 'arraybuffer'
          This.voiceSocket.onopen = () => {
            res()
          }
          This.voiceSocket.onerror = () => {
            This.trybuttonstatus = "hcaudioerror"
            rej()
          }
        } else {
          return res()
        }
      })
    },
    initStreamVoice(mediaSource) {
      var This = this
      return new Promise((r, j) => {
        Promise.all([
          This.initSocket(),
          new Promise((res) => {
            mediaSource.onsourceopen = () => {
              res()
            }
          }),
        ]).then(() => {
          r()
        })
      })
    },
    speakEvent(ins, force = true, end = false) {
      // ins = new Audio(require('@/assets/audio/bideyisheng.mp3'));
      return new Promise((res, rej) => {
        ins.onerror = (e) => {
          if (end) {
            this.endSpeak()
          }
          console.log(e)
          console.log('speakEvent - end')
          this.startrec() //开始录音
          res()
        }
        if (ins instanceof Audio) {
          console.log('speakEvent - audio')
          ins.onended = ins.onerror
          ins
            .play()
            .then(() => {
              console.log('Audio played successfully')
            })
            .catch((error) => {
              console.log('Failed to play audio:', error)
            })
        }
      })
    },
    endSpeak() {
      this.voiceIns.pause()
      this.voiceIns.currentTime = 0
      URL.revokeObjectURL(this.voiceIns.src) // 清除内存
      this.voiceIns.removeAttribute('src')
      this.voiceIns.onended = this.voiceIns.onerror = null
      this.sourceBuffer = void 0
      this.speechPushing = false
      if (this.voiceSocket && this.voiceSocket['pending']) {
        this.voiceSocket.close()
      }
      this.speechQuene.length = 0
    },
    getWSAudio(date, requestId) {
      return `X-Timestamp:${date}\r\nContent-Type:application/json; charset=utf-8\r\nPath:speech.config\r\n\r\n{"context":{"synthesis":{"audio":{"metadataoptions":{"sentenceBoundaryEnabled":"false","wordBoundaryEnabled":"true"},"outputFormat":"audio-24khz-48kbitrate-mono-mp3"}}}}`
    },
    getWSText(
      date,
      requestId,
      lang,
      voice,
      volume,
      rate,
      pitch,
      style,
      role,
      msg
    ) {
      let fmtVolume = volume === 1 ? '+0%' : volume * 100 - 100 + '%'
      let fmtRate = (rate >= 1 ? '+' : '') + (rate * 100 - 100) + '%'
      let fmtPitch = (pitch >= 1 ? '+' : '') + (pitch - 1) + 'Hz'

      return `X-RequestId:${requestId}\r\nContent-Type:application/ssml+xml\r\nX-Timestamp:${date}Z\r\nPath:ssml\r\n\r\n<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='https://www.w3.org/2001/mstts' xml:lang='${lang}'><voice name='${voice}'><prosody pitch='${fmtPitch}' rate='${fmtRate}' volume='${fmtVolume}'>${msg}</prosody></voice></speak>`
    },
    uuidv4() {
      let uuid = ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
      return uuid
    },
    async speechEvent(text) {
      var This = this
      This.trybuttonstatus = '' //重置按钮状态
      var existVoice = 2
      console.log('speechEvent')
      This.endSpeak()
      // currentVoiceIdx = idx;

      let type = 1
      let content = text
      let volume = 1
      let rate = 1
      let pitch = 1

      if (existVoice >= 2) {
        if (!this.voiceIns || this.voiceIns instanceof Audio === false) {
          this.voiceIns = new Audio()
        }
        let voice =
          'Microsoft Server Speech Text to Speech Voice (en-US, JennyNeural)'
        let key = content + voice + volume + rate + pitch
        // let currData = voiceData[key];

        if (this.supportMSE) {
          This.mediaSource = new MediaSource()
          This.voiceIns.src = URL.createObjectURL(This.mediaSource)
          await this.initStreamVoice(This.mediaSource)
          if (!This.sourceBuffer) {
            This.sourceBuffer = This.mediaSource.addSourceBuffer(This.voiceMIME)
          }
          This.sourceBuffer.onupdateend = function () {
            This.speechPushing = false
            if (This.speechQuene.length) {
              let buf = This.speechQuene.shift()
              if (buf['end']) {
                if (!This.sourceBuffer.buffered.length)
                  console.log('当前语音无法合成此消息，请选择其他语音或消息！')
                console.log('mediaSource.endOfStream()')
                This.mediaSource.endOfStream() // 结束流, 使得 onsourceended 能够触发
              } else {
                This.speechPushing = true
                This.sourceBuffer.appendBuffer(buf)
              }
            }
          }

          This.speechQuene.push = function (buffer) {
            if (
              !This.speechPushing &&
              This.sourceBuffer &&
              !This.sourceBuffer.updating
            ) {
              This.speechPushing = true
              This.sourceBuffer.appendBuffer(buffer)
            } else {
              Array.prototype.push.call(This.speechQuene, buffer)
            }
          }

          // let bufArray = []
          This.voiceSocket.onmessage = (e) => {
            
            if (e.data instanceof ArrayBuffer) {
             
              // let buf = e.data.slice(130)
              let buf = e.data.slice(130)

              // bufArray.push(buf)
              // This.sourceBuffer.appendBuffer(buf) //可删
              This.speechQuene.push(buf)
            } else if (e.data.indexOf('Path:turn.end') !== -1) {
              This.voiceSocket['pending'] = false
              // if (!(bufArray.length === 1 && bufArray[0].byteLength === 0)) voiceData[key] = new Blob(bufArray, { type: voiceMIME });
              if (!This.speechQuene.length && !This.speechPushing) {
               
                This.mediaSource.endOfStream()
              } else {
               
                let buf = new ArrayBuffer()
                buf['end'] = true
                This.speechQuene.push(buf)
              }
            }
          }
        } else {
          await This.initSocket()
        }
        let currDate = new Date().toString()
        let lang = 'en-AU'
        let uuid = This.uuidv4()

        This.voiceSocket.send(This.getWSAudio(currDate, uuid))
        This.voiceSocket.send(
          This.getWSText(
            currDate,
            uuid,
            lang,
            voice,
            volume,
            rate,
            pitch,
            undefined,
            undefined,
            content
          )
        )
        This.voiceSocket['pending'] = true
      }

      await This.speakEvent(This.voiceIns)
    },
    ///end 文字转语音/////////////////////////////////////////////////////////////////

    getList() {
      fetchListTest(this.listQuery).then((response) => {
        this.listData = response.data
        this.listData.forEach((element, index) => {
          element.index = index

          // element.children 中的每个项目中都有oralcate为分类，element.children中每个oralcate分类最终只保留随机一条数据，其他删除
          var oralcatearr1 = []
          var oralcatearr2 = []
          var oralcatearr3 = []
          var oralcatearr4 = []
          element.children.forEach((childitem, childindex) => {
              //childitem.oralcate 转为int
              childitem.oralcate = parseInt(childitem.oralcate)
              switch (childitem.oralcate) {
                case 1:
                  oralcatearr1.push(childitem)
                  break
                case 2:
                  oralcatearr2.push(childitem)
                  break
                case 3:
                  oralcatearr3.push(childitem)
                  break
                case 4:
                  oralcatearr4.push(childitem)
                  break
              }
          })
          //随机取一条
          var oralcatearr = []
          if (oralcatearr1.length > 0) {
            oralcatearr.push(
              oralcatearr1[Math.floor(Math.random() * oralcatearr1.length)]
            )
          }
          if (oralcatearr2.length > 0) {
            oralcatearr.push(
              oralcatearr2[Math.floor(Math.random() * oralcatearr2.length)]
            )
          }
          if (oralcatearr3.length > 0) {
            oralcatearr.push(
              oralcatearr3[Math.floor(Math.random() * oralcatearr3.length)]
            )
          }
          if (oralcatearr4.length > 0) {
            oralcatearr.push(
              oralcatearr4[Math.floor(Math.random() * oralcatearr4.length)]
            )
          }
          element.children = oralcatearr



          element.children.forEach((childitem, childindex) => {
            childitem.index = childindex
          })
        })
        this.paperid = response.paperid
        this.total = response.data.total
        //给当前行赋值
        this.currentRow = this.listData[0]
      })
    },

    /**
     * 主动结束录音下一题录音环节,还未提交数据
     * nextquestion
     */
    ChildQuestionEnd() {
      //主动录音结束
      this.currnetStatus = 'stop'
    },
    //nextlistData  已提交进入下一小题
    async NextChildQuestion() {
      
      var This = this
      
      // 再次尝试取消重试按钮
      This.trybuttonstatus = ''

      //chatgpt打个分
      var scoremessage = [
        {
          role: 'system',
          content:
            '你扮演ICAO飞行英语顶级专家。根据学生的回答，从流利度、结构、词汇、理解、互动对学生的回答进行评分，每一项满分6分；并根据每道题具体分析学生回答的优缺点，针对扣分项给出改进建议和参考答案。\n 输出示例：( [fluency] \n analysis: XXXXX \n score: XXXXX \n\n [structure] \n analysis: XXXXX \n score: XXXXX \n\n [vocabulary] \n analysis: XXXXX \n score: XXXXX \n\n  [comprehension] \n analysis: XXXXX \n score: XXXXX \n\n [interaction] \n analysis: XXXXX \n score: XXXXX ) 根据示例输出文本',
        },
      ]
      var qumessage = ''
      This.hisitems = []
      var qa_num = 1 //题号
      var qa_double = '' //答案和问题是否都加入了
      // console.log('进入大循环开始：----------------------------------')
      // console.log(this.chatgptmessage)
      this.chatgptmessage.forEach((element, index) => {
        // 如果是第三阶段，就跳过第一题
        // console.log(this.currentRow.children[this.listQuery.CurrnetChildIndex].oralcate)
        // console.log(index)
        // console.log(element.role)

        if (element.role == 'user') {
          if(This.currentRow.children[This.listQuery.CurrnetChildIndex].oralcate == "3" && index == 1){
            return
          }
            qumessage += 'answer ' + qa_num + ':' + element.content + '\n '
            This.hisitems.push({
              type: 'answer',
              id: qa_num,
              content: element.content,
            })
            qa_double += '1'
          
        } else if (element.role == 'assistant') {
          if(This.currentRow.children[This.listQuery.CurrnetChildIndex].oralcate == "3" && index == 1){
            qumessage += 'question ' + qa_num + ':' + element.content[0]['text'] + '\n '
            This.hisitems.push({
            type: 'question',
            id: qa_num,
            content: element.content[0]['text'],
          })
            qa_double += '1'
          }else{
          qumessage += 'question ' + qa_num + ':' + element.content + '\n '
          This.hisitems.push({
            type: 'question',
            id: qa_num,
            content: element.content,
          })
          qa_double += '1'
          }
        }

        if (qa_double == "11"){
          qa_double = ''
          qa_num += 1
        }

        
      })

      // if(this.currentRow.children[this.listQuery.CurrnetChildIndex].oralcate == 3){
      //   scoremessage.push({
      //     role: 'user',
      //     content: [
      //       {
      //       "type": "text",
      //       //告诉考官这是我本次考试收到的考题图片
      //       "text": "This is the picture of the exam questions I received for this exam", 
      //     },
      //     {
      //       "type": "image_url",
      //       "image_url": {
      //         "url":  this.currentRow.img_url,
      //       },
      //     },
      //     ],
      //   })
      // }

      scoremessage.push({
        role: 'user',
        content: qumessage,
      })

      // let APIKey = 'sk-f2EvU4auahAa47xyinTJNEOMZLH06jlo0VuMZAuMvi7m3J9p'
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + this.kimi_base_urls['apikey'])
      myHeaders.append('Content-Type', 'application/json')
      try {
        const fetchcontroller = new AbortController()
        const timeoutid = setTimeout(() => {
          fetchcontroller.abort()
        }, this.fetchtimeout)
      var chatres = await fetch(this.kimi_base_urls['url']+'chat/completions', {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          messages: scoremessage,
          // model: 'gpt-4-1106-preview',
          model: 'moonshot-v1-128k',
          // response_format: { type: "json_object" },
          // model:  'gpt-4',
          // max_tokens: 100, 
          stream: false,
          // temperature: roleTemp,
          // top_p: roleNature
        }),
         signal: fetchcontroller.signal
      })
      clearTimeout(timeoutid)
      }
      catch(error){
        This.trybuttonstatus = "scoreerror"
        This.gptbaseurlchange()
        console.log(error)
        return
      }



      chatres = await chatres.json()

      // 加入到历史中
      this.hisdata.push({
        //替换\n为<br>
        content: This.hisitems,
        score: chatres['choices'][0]['message']['content'].replace(/\n/g, '<br>'),
      })

      // console.log(this.hisdata)

      //终端执行

      //判断是否是最后一条数据
      // console.log(this.currentRow.children[this.listQuery.CurrnetChildIndex].index)
      // console.log(this.currentRow.children.length)
      // console.log( this.currentRow.children[this.listQuery.CurrnetChildIndex].index != this.currentRow.children.length - 1)
      if (
        this.currentRow.children[this.listQuery.CurrnetChildIndex].index !=
        this.currentRow.children.length - 1
      ) {
        this.currnetStatus = 'play'
        //this.currentRow = this.listData[this.currentRow.index + 1]
      }else{
        window.clearInterval(this.interval) //清空倒计时
         this.EverythingEnd = true
         this.$store.commit('timercountdown/END_HANDLER')
         return
      }
      this.listQuery.CurrnetChildIndex += 1

      //初始化信息进入下一题
      // 冲this.qa_qianzhui随机取一个
      var qa_qianzhui_index = Math.floor(Math.random() * this.qa_qianzhui.length)
      this.initcurrentmessage(
        this.qa_qianzhui[qa_qianzhui_index],
        this.currentRow.children[this.listQuery.CurrnetChildIndex].title,
        this.currentRow.children[this.listQuery.CurrnetChildIndex].oralcate
      )

      this.tmpspeechtext = this.qa_qianzhui[qa_qianzhui_index] +
          this.replaceTTSWord(this.currentRow.children[this.listQuery.CurrnetChildIndex].title)
      this.speechEvent(
        this.qa_qianzhui[qa_qianzhui_index] +
          this.replaceTTSWord(this.currentRow.children[this.listQuery.CurrnetChildIndex].title)
      )
    },
    startrec() {
      this.currnetStatus = 'rec'
      //先打开权限---正式开录音
      this.recOpen()
      this.downtimelisten()
    },
    /**
     * //倒计时和判断录音结束
     * isonly = true 单曲播放
     * isonly = false 顺序播放
     */
    downtimelisten() {
      this.currentcountdown = 59 //倒计时写死
      this.interval = window.setInterval(() => {
        if (this.currnetStatus == 'stop') {
          //主动结束录音
          this.recStop() //录音结束释放资源
          window.clearInterval(this.interval)
          this.currnetStatus == 'play' //录音状态结束。图标切换
        }
        //倒计时
        window.console.log(this.currentcountdown)
        //录制音频倒计时，并继续播放
        this.currentcountdown--
        if (this.currentcountdown < 0) {
          this.currnetStatus = 'stop'
          this.recStop() //录音结束释放资源
          window.clearInterval(this.interval) //清空倒计时
          this.currnetStatus == 'play' //录音状态结束。图标切换
        }
      }, 1000)
    },
    initcurrentmessage(qa_qianzhui,qatitle,stage) {
      // content:
      //       '你扮演ICAO飞行英语考官，你要针对一个问题对考生提问。学生在回答过程中，你可以适当的追问，以此来考察学生的英语水平和对航空英语的理解。',

      if(stage == "3"){
        this.chatgptmessage = [
        {
          role: 'system',
          content:
            // '根据图片内容，你扮演ICAO飞行英语考官，学生在回答后，你需要进行追问（100字以内）。',
             '中国民航总局飞行员英语能力考试（Pilot English Proficiency Examination of CAAC）简称”PEPEC“。是为了评估航空人员特别是飞行员和空中交通管制员的英语水平而设计的考试。这项考试符合国际民航组织（ICAO）的语言能力要求，并且评级尺度一致。根据图片内容，你扮演ICAO飞行英语考官，你要针对一个问题对考生提问。学生在回答过程中，你需要深入追问（字数控制在100以内），以此来考察学生的英语水平和对航空英语的理解。（可以幽默一些）',
        },
        {
          role: 'user',
          content: [
            {
            "type": "text",
            "text": "This is the picture of the exam questions I received for this exam",
          },
          {
            "type": "image_url",
            "image_url": {
              "url": this.currentRow.img_url
            }
          }
            ],
        },
        {
          role: 'assistant',
          content: qa_qianzhui + qatitle,
          // [
          //   {
          //   "type": "text",
          //   "text": qa_qianzhui + qatitle
          // },
          // {
          //   "type": "image_url",
          //   "image_url": {
          //     "url": this.currentRow.img_url
          //   }
          // }
          //   ],
        },
      ]
      }else{
      this.chatgptmessage = [
        {
          role: 'system',
          content:
            // '中国民航总局飞行员英语能力考试（Pilot English Proficiency Examination of CAAC）简称”PEPEC“。是为了评估航空人员特别是飞行员和空中交通管制员的英语水平而设计的考试。这项考试符合国际民航组织（ICAO）的语言能力要求，并且评级尺度一致。你扮演ICAO飞行英语考官，你要针对一个问题对考生提问。学生在回答过程中，你可以适当的追问（字数控制在100以内），以此来考察学生的英语水平和对航空英语的理解。',
             '中国民航总局飞行员英语能力考试（Pilot English Proficiency Examination of CAAC）简称”PEPEC“。是为了评估航空人员特别是飞行员和空中交通管制员的英语水平而设计的考试。这项考试符合国际民航组织（ICAO）的语言能力要求，并且评级尺度一致。你扮演ICAO飞行英语考官，你要针对一个问题对考生提问。学生在回答过程中，你需要深入追问（字数控制在100以内），以此来考察学生的英语水平和对航空英语的理解。（可以幽默一些）',
        },
        {
          role: 'assistant',
          content:  qa_qianzhui + qatitle
        },
      ]
      }


    },
    //只是上传单个录音
    async oneuploadrec(blob) {
      var This = this
      //上传录制音频post
      if (!blob) {
        this.reclog('请先录音，然后停止后再上传', 1, this.currentRow.id)
        return
      }
      console.log('开始alioss上传相关文件')

      //上传录音到openai并识别成文字
      // let APIKey = 'sk-f2EvU4auahAa47xyinTJNEOMZLH06jlo0VuMZAuMvi7m3J9p'
      var myHeaders = new Headers()
      myHeaders.append('Authorization', 'Bearer ' + This.gpt_base_urls[This.curent_gpt_base_url]['apikey'])
      myHeaders.append('Content-Type', 'application/json')
      var apiHeaders = {'Authorization':'Bearer ' + this.gpt_base_urls[this.curent_gpt_base_url]['apikey']}

      const fd = new FormData()
      fd.append('file', blob, 'test.mp3')
      // fd.append("file", blob, "text.wav");
      // fd.append('file', this.$refs.file.files[0])
      fd.append('model', 'whisper-1')
      fd.append('language', 'en')
      // fd.append('prompt','The conversation related to ICAO Aviation English')
      fd.append('prompt','air china two nine zero, flight level there one zero, you are cleared to land, runway 27L,QNH,ILS,VMC,SID,ADS-B,PEPEC,FMC,flight management computer,FMS,CAFUC,TCAS,RVSM')

      var speechtext = await speechtotext(this.gpt_base_urls[this.curent_gpt_base_url]['url'],fd,apiHeaders)
      // console.log(speechtext)
      // console.log(speechtext.type)
      // console.log(speechtext.type != undefined && speechtext.type == 'error')
      // 如果speechtext发生错误，重试按钮变为可用
      if (speechtext.type != undefined && speechtext.type == 'error') {
        this.trybuttonstatus = "speechtotexterror"
        This.gptbaseurlchange()
        return
      }else{
        this.trybuttonstatus = ""
      }
      

      // window.console.log(speechtext.text)
      // console.log(This.chatgptmessage)
      This.chatgptmessage.push({
        role: 'user',
        content: speechtext.text,
      })

      // 如果 chatgptcurrentitemlength 不大于 chatgptforitemnum，This.chatgptcurrentitemlength ++
      if (This.chatgptcurrentitemlength <= This.chatgptforitemnum) {

        try{
          // 将新文字和旧文字合并，发送给chatgpt并返回新的文字
          const fetchcontroller = new AbortController()
        const timeoutid = setTimeout(() => {
          fetchcontroller.abort()
        }, this.fetchtimeout)
        var chatres = await fetch(this.gpt_base_urls[this.curent_gpt_base_url]['url']+'chat/completions', {
          method: 'POST',
          headers: myHeaders,
          body: This.currentRow.children[This.listQuery.CurrnetChildIndex].oralcate == "3"?
          JSON.stringify({
            messages: This.chatgptmessage,
            // model:  'gpt-4-vision-preview',
            model: 'gpt-4o',
            // max_tokens: 100,
            // max_tokens: 300,
            stream: false,
            // temperature: roleTemp,
            // top_p: roleNature
          }):
          JSON.stringify({
            messages: This.chatgptmessage,
            // model:  'gpt-4',
            model: 'gpt-4o',
            // max_tokens: 100,
            
            stream: false,
            // temperature: roleTemp,
            // top_p: roleNature
          }),
          signal: fetchcontroller.signal
        })
        clearTimeout(timeoutid)
        }
        catch(error){
            console.log("fetch api:"+error);
            This.trybuttonstatus = "speechtotexterror"
            This.gptbaseurlchange()
            //删除最后一条
            This.chatgptmessage.pop()

            return
        }
        // 重试按钮变为可用
        if (chatres.error != undefined) {
          This.trybuttonstatus = "speechtotexterror"
          This.gptbaseurlchange()
          //删除最后一条
          This.chatgptmessage.pop()
          return
        }

        chatres = await chatres.json()
        // console.log(chatres)
        // console.log(chatres['choices'][0]['message']['content'])

        var assistantmes =This.replaceSensetiveWord(chatres['choices'][0]['message']['content'])

        This.chatgptmessage.push({
          role: 'assistant',
          content:
            // "Let's start with the question. " +
            assistantmes,
        })

        This.chatgptcurrentitemlength++

        This.tmpspeechtext = This.replaceTTSWord(assistantmes)
        This.speechEvent(This.replaceTTSWord(assistantmes))
        This.currnetStatus = 'play'
      } else {
        // 如果 chatgptcurrentitemlength 大于 chatgptforitemnum，This.chatgptcurrentitemlength = 1
        This.chatgptcurrentitemlength = 1
        This.NextChildQuestion() //切换下一行子题播放
      }

      // 全部结束，本题完毕弹出成绩
      // if (
      //   This.listQuery.CurrnetChildIndex ==
      //   This.listData[resmeata.meta.index].children.length - 1
      // ) {

      //   This.popupvisable = true
      //   return
      // }

      // end应修改-逻辑有问题
    },
    Closesubmitquestion() {
      //提交之前验证
      this.questiontipstatus = true
      //this.listData[0]  因为就一个题
      this.$store.dispatch('roleplayscore/setquestiondatas', this.listData[0])
      //跳转到答题页面
      this.$router.push({
        name: 'TrainInterviewTestScore',
        query: { paperid: this.paperid },
      })
      // window.console.log(this.listData)
    },

    gptbaseurlchange(){
      if(this.curent_gpt_base_url == 0){
        this.curent_gpt_base_url = 1
      }else{
        this.curent_gpt_base_url = 0
      }
    },

    //recorder录制音频
    //打开录音，请求权限
    recOpen: function () {
      var This = this
      var rec = (this.rec = Recorder({
        type: This.type,
        bitRate: This.bitRate,
        sampleRate: This.sampleRate,
        onProcess: function (buffers, powerLevel, duration, sampleRate) {
          This.duration = duration
          This.powerLevel = powerLevel
          This.wave.input(buffers[buffers.length - 1], powerLevel, sampleRate)
        },
      }))
      This.dialogInt = setTimeout(function () {
        //定时8秒后打开弹窗，用于监测浏览器没有发起权限请求的情况
        This.showDialog()
      }, 8000)
      rec.open(
        function () {
          This.dialogCancel()
          This.reclog(
            '已打开:' +
              This.type +
              ' ' +
              This.sampleRate +
              'hz ' +
              This.bitRate +
              'kbps',
            2
          )

          if (This.recaudioischeck) {
            //检测访问波浪图
            This.wave = Recorder.WaveView({ elem: '.ctrlProcessWaveischeck' })
          } else {
            This.wave = Recorder.WaveView({ elem: '.ctrlProcessWave' })
          }

          This.rec.start() //开始录制
        },
        function (msg, isUserNotAllow) {
          This.dialogCancel()
          This.reclog(
            (isUserNotAllow ? 'UserNotAllow，' : '') + '打开失败：' + msg,
            1
          )
          This.openmessbox(msg)
        }
      )
      This.waitDialogClickFn = function () {
        This.dialogCancel()
        This.reclog('打开失败：权限请求被忽略，用户主动点击的弹窗', 1)
        This.openmessbox('打开失败：权限请求被忽略，用户主动点击的弹窗')
      }
    },
    recStart: function () {
      //this.recOpen()
      if (!this.rec) {
        this.reclog('未打开录音', 1)
        // this.openmessbox('未打开录音')
        return
      }
      this.rec.start()
      var set = this.rec.set
      this.reclog(
        '录制中：' +
          set.type +
          ' ' +
          set.sampleRate +
          'hz ' +
          set.bitRate +
          'kbps'
      )
    },
    recStop: function () {
      var This = this
      var rec = This.rec
      This.rec = null
      if (!rec) {
        This.reclog('未打开录音', 1)
        // This.openmessbox('未打开录音')
        return
      }
      rec.stop(
        function (blob, duration) {
          This.reclog('已录制:', '', {
            blob: blob,
            duration: duration,
            rec: rec,
          })

          This.currentblob = blob
          This.duration = duration
          //if (!This.recaudioischeck) {
          //非录音检测开始上传
          This.oneuploadrec(blob)
          //}
        },
        function (s) {
          This.reclog('结束出错：' + s, 1)
          This.openmessbox('结束出错：' + s)
        },
        true
      ) //自动close
    },
    recStopischeck: function () {
      var This = this
      var rec = This.rec
      This.rec = null
      if (!rec) {
        This.reclog('未打开录音', 1)
        // This.openmessbox('未打开录音')
        return
      }
      rec.stop(
        function (blob, duration) {
          This.reclog('已录制:', '', {
            blob: blob,
            duration: duration,
            rec: rec,
          })

          This.currentblob = blob
        },
        function (s) {
          This.reclog('结束出错：' + s, 1)
          This.openmessbox('结束出错：' + s)
        },
        true
      ) //自动close
    },
    reclog: function (msg, color, res) {
      this.logs.splice(0, 0, {
        idx: this.logs.length,
        msg: msg,
        color: color,
        res: res,
        playMsg: '',
        down: 0,
        down64Val: '',
      })
    },
    openmessbox(msg) {
      this.$alert(msg, '请检查麦克风！', {
        confirmButtonText: '确定',
        callback: () => {
          this.$message({
            type: 'info',
            message: `提示: 再次检查！记得刷新页面！`,
          })
        },
      })
    },
    getTime: function () {
      var now = new Date()
      var t =
        ('0' + now.getHours()).substr(-2) +
        ':' +
        ('0' + now.getMinutes()).substr(-2) +
        ':' +
        ('0' + now.getSeconds()).substr(-2)
      return t
    },
    intp: function (s, len) {
      s = s == null ? '-' : s + ''
      if (s.length >= len) return s
      return ('_______' + s).substr(-len)
    },
    showDialog: function () {
      //我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调
      if (!/mobile/i.test(navigator.userAgent)) {
        return //只在移动端开启没有权限请求的检测
      }
      this.recOpenDialogShow = 1
    },
    dialogCancel: function () {
      clearTimeout(this.dialogInt)
      this.recOpenDialogShow = 0
    },
    waitDialogClick: function () {
      this.dialogCancel()
      this.waitDialogClickFn()
    },
  },
}
</script>

<style lang="scss">
.app-wrapper,
.hideSidebar {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.na_m_top {
  box-sizing: border-box;
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
   /* height: calc(100vh - 50px);*/
}
.na_m_container {
  height: 100%;
  .na_aside {
    background-color: #2e3d49;
  }
}

.title-img {
  background-color: red;
  background-image: linear-gradient(#e66465, #9198e5);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
  }
}

.woyaodao {
  height: 170px;
}
.el-submenu .el-submenu__title {
  height: 70px;
  line-height: 35px;
  padding: 5px;
  text-align: left;
}
.sidenum {
  padding-top: 40px;

  .el-button {
    margin-left: 10px;
    margin-bottom: 20px;
  }
  .el-button {
    padding: 14px;
  }
}
.blankdiv {
  margin: 50px;
}
.blankdivitem {
  float: left;
  margin-left: 5px;
  width: 100%;
  height: 250px;
}
.clear {
  clear: both;
}
.bottombutton {
  text-align: center;
  margin-top: 50px;
}
.el-collapse-item__header {
  color: rgba(22, 155, 213, 1);
  font-size: 14px;
  font-weight: 700;
  padding-left: 50px;
}

.selectgreenborder {
  color: green;
  .el-input__inner {
    border: 1px solid green;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: green;
  }
  .el-input__inner:focus {
    border-color: green;
  }
}
.selectredborder {
  color: red;
  .el-input__inner {
    border: 1px solid red;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: red;
  }
  .el-input__inner:focus {
    border-color: red;
  }
}
.fontsize {
  font-size: 40px;
}
.wquestion {
  i {
    font-size: 40px;
  }

  audio {
    vertical-align: middle;
  }
}

.myanswer {
  .boxingtu {
    float: right;
  }
  .buttonblank {
    float: right;
  }
  audio {
    vertical-align: middle;
  }
}

.src-content {
  height: 575px;
  margin-top: 10px;
  .src-pb {
    height: 95%;

    margin-left: 10px;
    border-radius: 4px;
    p {
      margin: 20px;
    }
  }
  .src-img {
    height: 95%;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #d7dae2;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.na_m_top {
  .grid-content.bg-purple {
    padding-bottom: 10px;
    text-align: left;
    padding-left: 50px;
    font-size: 16px;
  }
  .headimg {
    padding-bottom: 20px;
    margin: 0 auto;

    text-align: center;
    .imgtx {
      border: 5px solid #d7dae2;
      font-size: 150px;
    }
  }
  .headimg.active {
    .imgtx {
      border: 5px solid #409eff;
    }
  }
}

.qtit{
  padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
}

.qtitscore{
  padding: 8px 16px;
    background-color: #f0f9eb;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
}
</style>