<template>
  <div>
    <HomeHeader />
    <div style="width: 1200px; margin: 0 auto" class="banner">
      <el-carousel
        @change="bannerchange"
        ref="remarkCarusel"
        :autoplay="autoplay"
        height="350px"
      >
        <el-carousel-item v-for="(item, index) in bannerdata" :key="index">
          <div class="banneritem" :style="bannerbgstyle(item.imgurl)">
            <div class="nabut"
             v-if="
              $store.state.user.name == '' 
            "
            >
              <el-button
                @click="$router.push({
                    name: 'coursemain',
                    
                  })"
                size="medium"
                style="flex-grow: 1"
                round
                >注册免费体验</el-button
              >
            </div>
               <!-- @click="tryuselogin(item.examurl)" -->
            <!-- <div class="nabut"> -->
              <!-- <el-button
             
                size="medium"
                style="flex-grow: 1"
                round
                >系统演示</el-button
              > -->
            <!-- </div> -->
            <div class="nabut" v-else>
              <el-button
                @click="
                  $router.push({
                    name: 'coursemain',
                    
                  })
                "
            
                size="medium"
                style="flex-grow: 1"
                type="text"
                round
                >开始学习 <i class="el-icon-right"></i
              ></el-button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <div
        style="
          display: flex;
          height: 100px;
          font-size: 20px;
          color: white;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
        "
        @mouseenter="enter"
        @mouseleave="leave"
      >
        <div
          :class="current_banner_item[0]"
          @click="bannerchangeIndex(0)"
          style="flex-grow: 1"
        >
          飞行英语
        </div>
        <div
          :class="current_banner_item[1]"
          @click="bannerchangeIndex(1)"
          style="flex-grow: 1"
        >
          机务英语
        </div>
        <div
          :class="current_banner_item[2]"
          @click="bannerchangeIndex(2)"
          style="flex-grow: 1"
        >
          管制英语
        </div>
        <div
          :class="current_banner_item[3]"
          @click="bannerchangeIndex(3)"
          style="flex-grow: 1"
        >
          乘务英语
        </div>
      </div> -->
      <div class="hometitle">
        <div class="ceng">
          <span>系统介绍</span>
        </div>
      </div>
      <div class="tabscontent">
        <el-tabs v-model="activeName">
          <el-tab-pane label="概述" name="first">
            <div
              style="
                width: 95%;
                margin: 0 auto;
                font-size: 16px;
                padding-bottom: 50px;
                line-height: 28px;
              "
            >
              <div class="tiptitle"><strong>概述</strong></div>
              <div v-html="homedata.gs_gaisu"></div>
              <div class="tiptitle"><strong>功能</strong></div>
              <div v-html="homedata.gs_gongneng"></div>
              <div class="tiptitle"><strong>作用</strong></div>
              <div v-html="homedata.gs_zuoyong"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="自主学习" name="second">
            <div style="padding: 50px">
              <div style="text-align: center; font-size: 24px">
                此课程包含{{ coursedata.length }}门课程及词汇速查
              </div>
            </div>
            <div class="selflearn">
              <div
                class="item"
                v-for="(item, index) in coursedata"
                :key="index"
              >
                <div class="fl bhao">
                  <div class="flname">课程</div>
                  <div class="flnum">{{ index + 1 }}</div>
                  <div class="flzj">{{ item.coursebook.length }}个章节</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                    <span class="zh_cn" v-html="item.name_en"></span>
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p style="text-indent: 2em" v-html="item.name_content"></p>
                  <el-collapse>
                    <el-collapse-item title=" 课程目录" name="1">
                      <div
                        style="float: left; width: 50%; line-height: 35px"
                        v-for="(itemi, indexi) in item.coursebook"
                        :key="'km' + indexi"
                      >
                        <a
                          
                          @click="checktocoursekitem(index, indexi,item)"
                          v-if="itemi.url != ''"
                          >{{ itemi.title }}</a
                        >
                        <span v-else>{{ itemi.title }}</span>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <div style="clear: both"></div>
              </div>

              <!--附加-->
              <div
                class="item"
                v-for="(item, indexfj) in homedata.zzxx_fj"
                :key="'fj' + indexfj"
              >
                <div class="fl bhao">
                  <div class="flname">{{ item.no }}</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                   <a target="_blank" :href="item.url" class="zh_cn">{{ item.name_en }}</a> 
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p>
                    {{ item.name_content }}
                  </p>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="专项训练" name="third">
            <div style="padding: 50px">
              <div style="text-align: center; font-size: 24px">训练内容</div>
            </div>
            <div class="selflearn">
              <div
                class="item"
                v-for="(item, indexzxxl) in homedata.zxxl"
                :key="'zxxl' + indexzxxl"
              >
                <div class="fl bhao">
                  <div class="flname">专题</div>
                  <div class="flnum">{{ indexzxxl + 1 }}</div>
                  <div class="flzj">{{ homedata.zxxl.length }}个专题</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                    <span class="zh_cn">{{ item.name_en }} </span>
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p style="text-indent: 2em" v-html="item.name_content"></p>
                  <el-collapse>
                    <el-collapse-item title=" 训练模式" name="1">
                      <div
                        style="float: left; width: 50%; line-height: 35px"
                        v-for="(itemi, indexii) in item.coursebook"
                        :key="'zxxli' + indexii"
                      >
                        <a
                          style="cursor: pointer"
                          @click="checktocourseitem(itemi.url)"
                          v-if="itemi.url != ''"
                          >{{ itemi.title }}</a
                        >
                        <span v-else>{{ itemi.title }}</span>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="模拟考试" name="fourth">
            <div style="padding: 0px 50px 50px 50px">
              <div style="text-align: right; font-size: 24px">
               <a :href="homedata.mmks_pdf"> <el-button  type="primary" plain
                  ><i class="el-icon-download"></i> 考试大纲</el-button
                ></a>
              </div>
              <div style="text-align: center; font-size: 24px">试卷组成</div>
            </div>
            <div class="selflearn">
              <div
                class="item"
                v-for="(item, indexmm) in homedata.mmks"
                :key="'mm' + indexmm"
              >
                <div class="fl bhao">
                  <div class="flname">PART</div>
                  <div class="flnum">{{ indexmm + 1 }}</div>
                </div>
                <div class="fl bcke">
                  <div class="coursename">
                    <span class="zh_cn"> {{ item.name_en }} </span>
                    <span class="eng"> （{{ item.name }}） </span>
                  </div>
                  <p style="text-indent: 2em;" v-html="item.name_content"></p>
                  <el-divider></el-divider>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>


       <!-- <div class="qustion">
        <div
          style="
            margin: 0 auto;
            padding: 100px;
            text-align: center;
            font-size: 24px;
          "
        >
          常见问题
        </div>
        <div
          style="
            width: 60%;
            margin: 0 auto;
            padding: 20px;
            background-color: white;
          "
        >
          <el-collapse accordion style="margin: 0 auto">
            <el-collapse-item
              v-for="(item, indexq) in resdata.questions"
              :key="'qusetion' + indexq"
            >
              <template slot="title">
                {{ item.title }}
              </template>
              <div v-html="item.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div> -->
      <!-- <div class="hometitle">
        <div class="ceng">
          <span>关于平台</span>
        </div>
      </div>
      <div style="width: 100%">
        <p style="line-height: 30px; text-indent: 2em">
          民航专业英语动态教学训练与测试系统是依照民航CCAR-141部、CCAR-61部、CCAR-66部、CCAR-70部和航空专业院校教学大纲及“ICAO语言熟练要求执行手册”（DOC9835/AN453）自主研发的，面向飞行员、管制员、机务维修人员和乘务员英语教学与自学的多媒体辅助教学产品，具有教学、自主学习、专项训练和模拟测试功能；具有“系统性、实时性、开放性、再造性”的四大特点，产品遵循ICAO英语标准，依据原创的ICAO英语理论、英语教学模式和情景语境语言学习模式，利用现代最新的信息技术进行计算机仿真化；该系统有广度、有深度，覆盖了ICAO英语培训业务全寿命周期的各阶段工作内容，满足从事飞行员ICAO英语培训的所有角色需求；将难懂的苦燥的传统的专业英语教学变成生动化、趣味化、情景化的方式教、读、听、译、测，有效突破教学难点；产品在行业中的先进水平为“完全自主，国内首创，国内领先”，具有自主知识产权。
        </p>
        <img
          src="~@/assets/homeimg01.png"
          style="width: 351px; height: 233px; margin: 0 auto; display: block"
        />
      </div> -->
         <div style="height:50px;"></div>
         <div class="hometitle">
        <div class="ceng">
          <span>友情链接</span>
        </div>
      </div>
      <el-row :gutter="12" class="nacard">
  <el-col :span="8" >
    <a href="http://www.caac.gov.cn/index.html" target="_blank"><el-card shadow="hover" >
    中国民用航空局  
    </el-card></a>  
  </el-col>
  <el-col :span="8">
     <a href="http://www.caac.gov.cn/dev/fbs/" target="_blank">
    <el-card shadow="hover">
     中国民航局飞行标准司
    </el-card>
    </a>    
  </el-col>
  <!-- <el-col :span="6">
    <a href="http://www.atmb.net.cn/" target="_blank">
    <el-card shadow="hover">
      空中交通管理局
    </el-card>
    </a>   
  </el-col> -->
  <el-col :span="8">
    <a href="http://www.camac.org.cn/" target="_blank">
    <el-card shadow="hover">
    中国民用航空维修协会
    </el-card>
    </a>  
  </el-col>
</el-row>
    </div>
     <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div style="text-align: center">
        <span>{{ centerDialogcontent }}</span>
      </div>
    </el-dialog>
    <HomeFooter />
  </div>
</template>

<script>
import { fetchList, CoursefetchList } from '@/api/home'
import HomeHeader from '@/components/HomeHeader'
import HomeFooter from '@/components/HomeFooter'
export default {
  name: 'Home',
  components: { HomeHeader, HomeFooter },
  data() {
    return {
      activeName: 'first',
      activeIndex: '0',
      current_banner_item_index: 0,
      current_banner_item: ['active', 'item_index', 'item_index', 'item_index'],
      bannerdata: [
        {'imgurl': 'bannerbg01.jpg','courseurl':'/coursemain','examurl':'/examlist'},
        // {'imgurl': 'bannerbg03.png','courseurl':'/mcoursemain','examurl':'/mexamlist'},
        // {'imgurl': 'bannerbg02.png','courseurl':'/aetscoursemain','examurl':'/aetsexamlist'},
        // {'imgurl': 'bannerbg04.png','courseurl':'/stewardcoursemain','examurl':'/stewardexamlist'},
      ],



      autoplay: false,
homedata: [],

      loginForm: {
        username: '',
        password: '',
      },
      otherQuery: {},
      coursedata: [],
      courseroutername : '',
      centerDialogVisible :false,
      centerDialogcontent: '',
    }
  },
  created() {
    //window.location.href = '/home.html'
     this.getlist()
    this.getcourselist()
     this.roles = this.$store.state.user.roles
  },
  methods: {
        getlist() {
      fetchList().then((response) => {
        var This = this
        this.resdata = response.data
        var cateid = 0
        switch (parseInt(cateid)) {
          case 0:
            This.homedata = This.resdata.pepec
            break
          case 1:
            This.homedata = This.resdata.maintenance
            break
          case 2:
            This.homedata = This.resdata.aets
            break
          case 3:
            This.homedata = This.resdata.steward
            break

          default:
            break
        }
      })
    },
    getcourselist() {
      var This = this
      CoursefetchList(0).then((response) => {
        This.coursedata = response.data
      })
    },
    tryuselogin(url) {
    
      this.loginForm.username = 'sample@qq.com'
      this.loginForm.password = '123456'
      this.loading = true
      this.$store
        .dispatch('user/login', this.loginForm)
        .then(() => {
          this.$router.push({
            path: url,
            query: this.otherQuery,
          })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    bannerbgstyle(item) {
      return 'background-image: url(' + require('@/' + '/assets/' + item) + ')'
    },
    bannerchangeIndex(index) {
      this.$refs.remarkCarusel.setActiveItem(index)
      this.bannerchange(index)
    },
        checktocourseitem(url) {
      if (this.$store.state.user.name == '') {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未登录！'
        return
      }
      // var cateid = this.$route.query.cate
      var cateid = 0
      var tmeint = parseInt(cateid) + 1
      
      if (this.roles.indexOf(tmeint.toString()) !== -1) {
        this.$router.push({ path: url })
      } else {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未购买此课程！'
      }
    },
        checktocoursekitem(chapterindex, coureindex, item) {
      if (this.$store.state.user.name == '') {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未登录！'
        return
      }
      // var cateid = this.$route.query.cate
      var cateid = 0
      
      //链接生成
      switch (parseInt(cateid)) {
        case 0:
          this.courseroutername = 'course'
          chapterindex = item.id
          break;
        case 1:
          this.courseroutername = 'jwcourse'
          break;
        case 2:
          this.courseroutername = 'kgcourse'
          break;

        default:
          break;
      }
      var tmeint = parseInt(cateid) + 1
     
      if (this.roles.indexOf(tmeint.toString()) !== -1) {
        this.$router.push({
          name: this.courseroutername,
          query: { cate_id: chapterindex, menuindex: coureindex },
        })
      } else {
        this.centerDialogVisible = true
        this.centerDialogcontent = '您还未购买此课程！'
      }
    },
    bannerchange(index) {
      this.current_banner_item_index = index
      switch (index) {
        case 0:
          this.current_banner_item = [
            'active',
            'item_index',
            'item_index',
            'item_index',
          ]
          break
        case 1:
          this.current_banner_item = [
            'item_index',
            'active',
            'item_index',
            'item_index',
          ]
          break
        case 2:
          this.current_banner_item = [
            'item_index',
            'item_index',
            'active',
            'item_index',
          ]
          break
        case 3:
          this.current_banner_item = [
            'item_index',
            'item_index',
            'item_index',
            'active',
          ]
          break
        default:
          break
      }
    },
    enter(e) {
      this.autoplay = false
    },
    leave(e) {
      this.autoplay = true
    },
  },
}
</script>
<style  lang="scss" scoped>
body a {
  text-decoration: none;
}
.hometitle {
  height: 40px;
  background: url('~@/assets/hometitlebg01.svg') no-repeat center center;
  font-size: 28px;
  text-align: center;
  margin: 50px 0;
  .ceng {
    background-color: white;
    margin: 0 auto;
    width: 150px;
  }
}
.banneritem {
  width: 100%;
  height: 400px;
  padding-top: 100px;
  .nabut {
    padding: 60px 0 0 80px;
  }
  ::v-deep .el-button--text {
    color: white;
  }
  ::v-deep .el-button {
    color: white;
    border-color: white;
    background-color: unset;
  }

  ::v-deep .el-button:active {
    background-color: white;
    color: #409eff;
    border-color: #409eff;
  }
  ::v-deep .el-button:hover {
    background-color: white;
    color: #409eff;
    border-color: #409eff;
  }

  ::v-deep .last {
    border: 0px;
  }
}

.banner {
  .item_index {
    background-color: rgba(1, 122, 155, 1);
  }
  .active {
    background-color: rgba(1, 122, 155, 0.8);
  }
}
.el-menu-demo {
  ::v-deep .el-menu.el-menu--horizontal {
    border-bottom: solid 1px white;
  }
  ::v-deep li,
  ::v-deep .el-submenu__title {
    font-size: 16px;
    font-weight: 600;
  }
}
.nacard {
  ::v-deep .el-card{
    border:3px solid #EBEEF5;
    cursor: pointer;
    text-align: center;
  }
}
footer {
  margin-top: 100px;
  background-color: rgba(35, 74, 102, 1);
  height: 150px;
  text-shadow: #000 0.1em 0.1em 0.1em;
  color: white;
  // line-height: 100px;
  font-size: 14px;
  text-align: center;
}




.tabscontent {
  width: 100%;
  ::v-deep .el-tabs__nav {
    white-space: nowrap;
    position: unset;
    transition: transform 0.3s;
    float: none;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
  }
}

.selflearn {
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #7f7f7f;
  .item {
    margin-bottom: 50px;
  }
  .fl {
    float: left;
  }
  .bhao {
    width: 10%;
    text-align: left;
    text-align: center;
  }
  .flname {
    letter-spacing: 5px;
    width: 70px;
  }
  .flnum {
    padding: 10px 0;
    font-size: 48px;
    width: 70px;
    
  }
  .flzj {
    width: 70px;
  }

  .coursename {
    font-size: 20px;
    .zh_cn{
      font-size: 20px;
      font-weight: 700;
      color: #1a76d1;
    }
  }

  .bcke {
    width: 90%;
    text-align: left;
    p {
    }
    a{
      cursor: pointer;
      color: #136ec2;
      font-size: 14px;
    }
    a:hover{
      color: #66b1ff;
      text-decoration: underline;
    }
  }
}
.tabscontent{
 ::v-deep .el-tabs__item{
    font-size: 18px;
}
}



.qustion {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(245, 247, 250, 1);
  padding-bottom: 100px;
}

.tiptitle {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid rgba(1, 122, 155, 1);
  margin: 20px 0;
}
</style>
