const state = {
   paperid: null,
   listData: null,
   result: {},
}

const mutations = {
  SET_QUESTIONDATAS: (state, listData,paperid) => {
    state.paperid = paperid
    state.listData = listData
  },
  SET_RESULT: (state, arr) => {
      state.result[arr.questionid] = arr.value
  },
  CLEAR_RESULT: () => {
    state.result = {}
},
}

const actions = {
  setquestiondatas({ commit }, listData,paperid) {
    commit('SET_QUESTIONDATAS', listData,paperid)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
