import request from '@/utils/request'

export function getMessage(query) {
  return request({
    url: '/icao/message/getMessage',
    method: 'get',
    params: query
  })
}

export function getlastver() {
  return request({
    url: '/icao/version/getlastver',
    method: 'get',
  })
}


/**
 * 已读消息提交
 * @param {*} query 
 * @returns 
 */
export function hasRead(query) {
  return request({
    url: '/icao/message/hasRead',
    method: 'get',
    params: query
  })
}

/**
 * 获取具体某节课的所有课后题
 * @param {chapterid（章节ID，必填）} query 
 * @returns 
 */
export function hasRead2(query) {
  return request({
    url: '/icao/message/hasRead2',
    method: 'get',
    params: query
  })
}