<template>
  <div :class="classObj" class="app-wrapper">
    <div  class="drawer-bg"  />
   
    <div class="main-container">
    
      <TrainMain/>
     
    </div>
  </div>
</template>

<script>
import  TrainMain  from "./components/main";
import { mapState } from 'vuex'


export default {
    name: 'ChatgptInterview',
    components:{TrainMain},
      data() {
    return {
      isopenstreammedia:
        localStorage.getItem('isstreamasr') != undefined
          ? Boolean(localStorage.getItem('isstreamasr'))
          : false,
    }
  },
    computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
}
</script>

<style lang="scss">
  @import "~@/styles/variables.scss";
  @import "~@/styles/sidebar.scss";

</style>

