import Cookies from 'js-cookie'

const languageKey = 'Language'

export function getLanguage() {
  return Cookies.get(languageKey)
}

export function setLanguage(value) {
  return Cookies.set(languageKey, value)
}

export function removeLanguage() {
  return Cookies.remove(languageKey)
}
