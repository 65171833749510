import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_CHATGPT_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests 会涉及到内部实时服务器传输
  timeout: 500000000000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  
  config => {
    // do something before request is sent
    //window.console.log('jsjsjsjjsjsjs')
    //window.console.log(store.getters.token)
    // if (store.getters.token) {

    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation

    //   config.headers['Authorization'] = 'bearer sk-f2EvU4auahAa47xyinTJNEOMZLH06jlo0VuMZAuMvi7m3J9p'

    // }
    if (config.contentType === "json") {
      config.headers['Content-Type'] = "application/json; charset=UTF-8"
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom status
   * Here is just an example
   * You can also judge the status by HTTP Status status
   */
  response => {
   
    
    const res = response.data


  
      return res
    
    
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    // return Promise.reject(error)
    return {type: 'error', message: error}
  }
)

export default service
