const state = {
   questiondatas: null,
}

const mutations = {
  SET_QUESTIONDATAS: (state, questiondatas) => {
    state.questiondatas = questiondatas
  },
}

const actions = {
  setquestiondatas({ commit }, questiondatas) {
    commit('SET_QUESTIONDATAS', questiondatas)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
